import React, { Component } from 'react';
import styled from 'styled-components';
import Gs from '../Theme/globalStyles';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import LeftMBX from '../Component/leftBar'
import GetwayStatus from '../Component/popup/gatewayStatus'
import Collapse from '@kunukn/react-collapse';
import Media from '../Theme/media-breackpoint';
import { Scrollbars } from 'react-custom-scrollbars';

class Gateway extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 1,
            dropDown: false,
            popup01: false
        }
    }

    render() {

        return (
            <>
                <MainContainer>
                    <LeftMBX />
                    <RightContainer>
                        <DSTitle01 className="wow fadeInUp" data-wow-delay="0.1s">
                            Gateway
                            <div className="linkBX01"> <a href="javascript:void(0);">How it works?</a> <span>|</span> <a href="/buy-tokens">Buy tokens from the Gateway</a>
                            {/* <a href="javascript:void(0);" onClick={() => { this.setState({ popup01: true }) }}>Buy tokens from the Gateway</a> */}</div>
                        </DSTitle01>
                        <DSTitle02 className="wow fadeInUp v1" data-wow-delay="0.1s">Sell tokens via Gateway<span data-tip='Comming Soon' className="helpIco"><i className="fas fa-question-circle"></i></span>
                        </DSTitle02>
                        <FormMBX01 className="wow fadeInUp" data-wow-delay="0.1s">
                            <FormSbxLabel>Token symbol</FormSbxLabel>
                            <FormSbxContent >
                                <DDMenu onClick={() => this.onToggle02(1)}>
                                    BNB Token
                                    <div className="DDContainer">
                                        <Collapse className={"collapse " + (this.state.dropDown === 1 ? "active" : "")}
                                            isOpen={this.state.dropDown === 1}
                                            onChange={({ state }) => { this.setState({ item1: state }); }}
                                            onInit={({ state }) => { this.setState({ item1: state }); }}>
                                            <div className="DDSubContainer">
                                                <button>BNB</button>
                                                <button>ETH</button>
                                                <button>FTM</button>
                                            </div>
                                        </Collapse>
                                    </div>
                                </DDMenu>
                            </FormSbxContent>
                        </FormMBX01>

                        <FormMBX01 className="wow fadeInUp" data-wow-delay="0.1s">
                            <FormSbxLabel>Choose your liquidity channel <span data-html='true' data-tip='Not all projects utilize the PDO to help with liquidity. If your token does not have an active PDO on PDO.Finance please reach out to the token admin and have them launch a free PDO' className="helpIco"><i className="fas fa-question-circle"></i></span></FormSbxLabel>
                            <FormSbxContent >
                                <div className="md-checkbox md-checkbox-inline">
                                    <input type="checkbox" id="checkbox01" name="" />
                                    <label htmlFor="checkbox01">
                                        PDO <span data-html='true' data-tip='Not all projects utilize the PDO to help with liquidity. If your token does not have an active PDO on PDO.Finance please reach out to the token admin and have them launch a free PDO' className="helpIco"><i className="fas fa-question-circle"></i></span>
                                    </label>
                                </div>
                                <div className="md-checkbox md-checkbox-inline">
                                    <input type="checkbox" id="checkbox02" name="" />
                                    <label htmlFor="checkbox02">
                                        Swap Provider <span data-html='true' data-tip='Not all projects utilize the PDO to help with liquidity. If your token does not have an active PDO on PDO.Finance please reach out to the token admin and have them launch a free PDO' className="helpIco"><i className="fas fa-question-circle"></i></span>
                                    </label>
                                    <span className="error-msg">Access liquidity via SP trigger incentive fee<span data-html='true' data-tip='Someting' className="helpIco"><i className="fas fa-question-circle"></i></span></span>

                                </div>
                            </FormSbxContent>
                        </FormMBX01>

                        <FormMBX01 className="wow fadeInUp" data-wow-delay="0.1s">
                            <FormSbxLabel>Token amount</FormSbxLabel>
                            <FormSbxContent >
                                <DDMenu className="formBox">
                                    <input type="text" />
                                </DDMenu>
                            </FormSbxContent>
                        </FormMBX01>
                        
                        <FormMBX01 className="wow fadeInUp v1" data-wow-delay="0.1s">
                            <FormSbxLabel> </FormSbxLabel>
                            <FormSbxContent >
                                <button className="PlaOrBTN01">ADD TO THE GATEWAY</button>
                            </FormSbxContent>
                        </FormMBX01>
                        <PlacOrdMBX className="wow fadeInRight" data-wow-delay="0.1s">

                            <DSTitle02>Ledger</DSTitle02>
                            <DSTabLinkBX>
                                <button onClick={() => this.onToggle(1)} className={(this.state.index === 1 ? "active" : "")}>Pending</button>
                                <button onClick={() => this.onToggle(2)} className={(this.state.index === 2 ? "active" : "")}>Sold</button>
                            </DSTabLinkBX>
                            <Scrollbars className={"custom-scroll tab-content" + (this.state.index === 1 ? "show" : "")} 
                                    autoHeight autoHide autoHideTimeout={1000} autoHideDuration={200}
                                    autoHeightMin={229}
                                    autoHeightMax={229}
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                                    renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                                    renderView={props => <div {...props} className="view"/>}>
                                <OpfyTableBX>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="20">
                                        <tbody>
                                            <tr>
                                                <th align="left" valign="middle" scope="col">Token Symbol</th>
                                                <th align="left" valign="middle" scope="col">Liquidity Channel</th>
                                                <th align="left" valign="middle" scope="col">Pending Amount</th>
                                                <th align="left" valign="middle" scope="col">Face Value</th>
                                                <th align="left" valign="middle" scope="col">Incentive Fee</th>
                                                <th align="left" valign="middle" scope="col">Proof</th>
                                                <th align="center" valign="middle" scope="col">Cancel Pending</th>
                                            </tr>
                                            <tr align="left" valign="middle">
                                                <td>SMART</td>
                                                <td>PDO</td>
                                                <td>100</td>
                                                <td>$253.25</td>
                                                <td>0%</td>
                                                <td className="color02">0xe39...075D</td>
                                                <td align="center" valign="middle"><button className='deleteBTN'><i className="far fa-times-circle"></i></button></td>
                                            </tr>
                                            <tr align="left" valign="middle">
                                                <td>PDO</td>
                                                <td>SP</td>
                                                <td>100</td>
                                                <td>$150</td>
                                                <td>10%</td>
                                                <td className="color02">0xe39...075D</td>
                                                <td align="center" valign="middle"><button className='deleteBTN'><i className="far fa-times-circle"></i></button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </OpfyTableBX>
                            </Scrollbars>

                            <Scrollbars className={"custom-scroll tab-content" + (this.state.index === 2 ? "show" : "")}
                                    autoHeight autoHide autoHideTimeout={1000} autoHideDuration={200}
                                    autoHeightMin={229}
                                    autoHeightMax={229}
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                                    renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                                    renderView={props => <div {...props} className="view"/>}>
                                <OpfyTableBX>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="20">
                                        <tbody>
                                            <tr>
                                                <th align="left" valign="middle" scope="col">Token Symbol</th>
                                                <th align="left" valign="middle" scope="col">Liquidity Channel</th>
                                                <th align="left" valign="middle" scope="col">Pending Amount</th>
                                                <th align="left" valign="middle" scope="col">Face Value</th>
                                                <th align="left" valign="middle" scope="col">Incentive Fee</th>
                                                <th align="left" valign="middle" scope="col">Proof</th>
                                                <th align="center" valign="middle" scope="col">Claim Fund</th>
                                            </tr>
                                            <tr align="left" valign="middle">
                                                <td>SMART</td>
                                                <td>PDO</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0%</td>
                                                <td className="color02">0xe39...075D</td>
                                                <td align="center" valign="middle"><button className='btn-primary'>CLAIM</button></td>
                                            </tr>
                                            <tr align="left" valign="middle">
                                                <td>PDO</td>
                                                <td>PDO</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0%</td>
                                                <td className="color02">0xe39...075D</td>
                                                <td align="center" valign="middle"><button className='btn-primary'>CLAIM</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </OpfyTableBX>
                            </Scrollbars>
                        </PlacOrdMBX>
                    </RightContainer>
                </MainContainer>

                <GetwayStatus isOpen={this.state.popup01} dismiss={() => { this.setState({ popup01: false }) }} />
                <ReactTooltip effect="solid" className="myTip" />
            </>
        );
    }
    onToggle = index =>
        this.setState(state => ({ index: state.index === index ? null : index }));
    onToggle02 = dropDown =>
        this.setState(state => ({ dropDown: state.dropDown === dropDown ? null : dropDown }));
}



// Common Style Div 


const FlexDiv = styled.div`
display: flex; align-items: center; justify-content:center; flex-wrap:wrap; width:100%;
`;

const MainContainer = styled(FlexDiv)`
 width: 100%; align-items:stretch; justify-content:flex-start; 
`
const RightContainer = styled(FlexDiv)`
  width:calc(100% - 82px); align-items: flex-start; justify-content:flex-start; padding:18px 56px; align-content:baseline;
  ${Media.md} {
    padding:25px 20px;
  }
  ${Media.sm} {
    width:100%;
  }
`;

const FormMBX01 = styled(FlexDiv)`
    justify-content:flex-start; flex-direction:row; margin-bottom:20px;
    ${Media.sm} {
        display:block;
    }
    &.v1{}
`;

const FormSbxLabel = styled(FlexDiv)`
    width:30%; justify-content:flex-start; font-size:16px; font-weight:700;
    ${Media.sm} {
        width:100%; margin-bottom:15px;
    }
`;

const FormSbxContent = styled(FlexDiv)`
    width:70%; justify-content:flex-start;
    ${Media.sm} {
        width:100%;
    }
    .md-radio label{ font-size:16px; color:#fff; font-weight:400;}
    .md-radio label:after{ background-color:#00f02b;}

    .md-radio label:before, .md-radio input[type="radio"]:checked + label:before { border: 2px solid #5a5e67;  left: -2px; top: -2px; width: 24px; height: 24px;}

    .md-checkbox {width: 402px; margin: 15px 0px;}
    .md-checkbox .error-msg {margin-left: 42px;}
    .error-msg {display: block; color: #c32b2d; font-size: 12px; margin: 10px 0 0;
        span {font-size: 10px;}
    }

    .PlaOrBTN01{ min-width:344px; font-size:16px; font-weight:700; height:60px;  color:#fff; border-radius:10px; background-color:inherit; border: 2px solid #00f02b; box-shadow: 0 0 10px #00f02b;
        :hover{ background-color:#00f02b;}
        ${Media.sm} {
            min-width:auto; width:100%;
        }
    } 
`;

const DDMenu = styled(FlexDiv)`
    justify-content:flex-start; position:relative; height:56px; border-radius:10px;  border:2px solid #5a5e67;  
    font-size: 16px; font-weight:400; padding:0 15px;
    i{max-width:80px; margin-right:10px;}
    :after{ content: ""; border-width:7px; border-style:solid; border-color:#fff transparent transparent transparent; position:absolute;   right: 16px;  top: 25px; } 
    &.formBox{
        input{ width:100%; height:52px; padding:6px 0; background-color:transparent; border:0px; color:#fff; font-size: 16px; font-weight:400;}
        :after{ display:none;}
    }
    &.noBorder{
        :after{ display:none;}
        border:0px;
    } 
    .DDContainer{ width:100%; z-index:10;   position:absolute; left:0; top:calc(100% + 6px);}
    .DDSubContainer{ width:100%;  color:white; border-radius:10px; background-color:rgba(41,42,48,0.95); padding:10px; 
        margin:0 0 8px 0; 
        button{ width:100%; color:#fff; border-radius:8px; text-align:left;display:block; padding:10px 14px; border:none; outline:none; 
            :hover{  background-color:rgba(255,255,255,0.05); }
        } 
    } 
`;

const DSTabLinkBX = styled(FlexDiv)`
    justify-content:flex-start; margin-bottom:50px;
    button{ font-size:16px; font-weight:700; color:#fff; margin-right:35px; padding: 0;
        :hover, &.active{ color:#00f02b;}
    }
`;

const DSTitle01 = styled(FlexDiv)`
    justify-content:space-between; font:700 36px/40px 'Kanit', arial; color:#00f02b; 
    .DSrPart{ display: flex; align-items:center; justify-content:center; font-size:16px; color:#fff; font-weight:700; }
    ${Media.xs} {
        font:700 30px/34px 'Kanit', arial; display:block;
    }
    .linkBX01{ display: flex; align-items:center; justify-content:center; font-size:16px; color:#5a5e67; font-weight:300; text-transform:initial;
        a{ color:#00f02b; margin:0 8px; font-family:'IBM Plex Mono', Arial,sans-serif; font-weight:700; 
            :hover{text-decoration:underline;}
            :first-child{
                ${Media.xs} 
                {
                    margin:0px 8px 0px 0px;
                }
            }
        }
        ${Media.xs} {
            justify-content:flex-start; flex-flow: column; align-items: flex-start; margin-top:15px;
            a {margin: 0;}
            span {display: none;}
        }
    }
`;

const DSTitle02 = styled(FlexDiv)`
    justify-content:flex-start; letter-spacing:-0.25px; font:700 21px/40px 'IBM Plex Mono', arial; color:#ffffff; margin:52px 0px 32px;
    &.v1{
        ${Media.sm} {
            margin:40px 0px 15px;
        }   
    }
`;

const TabNav = styled(FlexDiv)`
padding-top:50px; flex-direction:row; 
    .tab-Link{font-size:24px;font-weight:700;color:#fff;width:33.33%;text-align:center;border-bottom:3px solid #393d46;padding-bottom:30px; position:relative;
        i{ position:absolute; top: -3px;  margin-left: 3px;
            i{font-size:14px; color:#000; width:23px; height:23px; background-color:#00f02b; font-style:normal; display:flex; align-items:center;justify-content:center; border-radius:50%;}
        } 
    }
    .tab-Link.active{color:#00f02b;border-bottom-color:#00f02b;} 
`
const OpfyTableBX = styled(FlexDiv)` 
    margin:0px; font-size:14px; color:#fff; justify-content:flex-start;
    p{ color:#00f02b; font-size:12px; margin-top:15px; text-align:left; width:100%;} 
    .sortLink{ position:absolute; right:12px; color:#9a9a9a; top: 18px; font-size:18px; :hover{color:#fff;}}
    .deleteBTN{ background: transparent; border:none; font-size:24px; color:#53575d; margin:0 auto; :hover{color:#c32b2d; filter: drop-shadow(0 0 3px #c32b2d);}    }
    .OpfyBTNbar{ display: flex; align-items:center; justify-content:flex-start; width:210px;

        &.v2{width:180px; justify-content: center;}

        .btn01{width:155px; font-weight:700; height:40px; border:2px solid #00f02b; border-radius:5px; color:#00f02b; :hover{ color:#000; background-color:#00f02b;} }
        .btn02{ color:#858686; padding:10px; margin-left:6px; :hover{ color:#c32b2d; }}
    }
    .btn-primary {font-size: 14px; font-weight: 700; color: #00f02b; border-radius: 8px; background-color: inherit; border: 2px solid #00f02b; box-shadow: 0 0 6px #00f02b; padding: 10px 10px 10px 10px; display: block; width: 100%;}
    table{
        min-width: max-content; width:100%;
    }
    table tr td{ border:1px solid #545861; padding:10px 20px;
        &:nth-last-child(01){ border-right:0px; }
        &:nth-child(01){ border-left:0px; color:#00f02b;  a{color:#4b4bff; :hover{text-decoration:underline}}} 
        &.bor01{border-right:5px solid #545861; }
        &.color02{ color:#3131ff;}
    }
    table tr th{ border:2px solid #545861; color:#9a9a9a; position:relative; width: 14.28%;
        &:nth-last-child(01){ border-right:0px;}
        &:nth-child(01){ border-left:0px;}
        &.bor01{border-right:5px solid #545861; }
    } 
    table tr:hover td{background-color:#010202;}
`;

const PlacOrdMBX = styled(FlexDiv)`
    padding:0px 0px 50px;  color:#fff; border-top:1px solid #1e2127; margin-top:50px;
    .tab-content {display:none;}
    .show {display:block;}
`;

export default Gateway;