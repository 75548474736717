import React, { Component, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import Modal from "../modal";
import Collapse from "@kunukn/react-collapse";
import Media from "../../Theme/media-breackpoint";
// icon images
import SwapIcon from "../../Assets/images/swapicon.png";
import SwapIconred from "../../Assets/images/exhanICON02.png";
import ExchnICO from "../../Assets/images/exhanICON.png";
import ExchnICO02 from "../../Assets/images/exhanICON02.png";
import chain01 from "../../Assets/images/chain01.png";
import {
  addDEX,
  addPairAddress,
  getFactoryDetails,
  getTokenPrice,
  otcBuyToken,
  otcTransferToken,
} from "../../Service/otc_function";
import notificationConfig from "../../config/notificationConfig";
import {
  ACTIVE_CHAINS,
  ACTIVE_CHAINS_DECIMAL,
  BASE_API_HOST,
  currencyAddresses,
  nativeTokenList,
  supportedStableCoins,
  ZERO_ADDRESS,
} from "../../constants";
import Web3 from "web3";
import ReactLoaderSpinner from "react-loader-spinner";
import { changeNetwork, getChainId } from "../../Service/metamask_function";
import { GlobalContext } from "../../Context/globalContext";

function BuyReqPopup(props) {
  const globalContext = useContext(GlobalContext);

  const [ToggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const [isOpen, setOpen] = React.useState(true);

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";

  useEffect(() => {
    setToggleState(props.newOfferType);
  }, [props.newOfferType]);

  const [dropDown, setDropDown] = useState(false);
  const [index, setIndex] = useState(1);
  const [tokenToPayWith, setTokenToPayWith] = useState("BNB");
  const [tokenToReceive, setTokenToReceive] = useState(null);
  const [tokenToBuy, setTokenToBuy] = useState(null);
  const [tokenToBuyDEX, setTokenToBuyDEX] = useState(null);
  const [tokenToSell, setTokenToSell] = useState(null);
  const [tokenToSellDEX, setTokenToSellDEX] = useState(null);
  const [budgetAvailable, setBudgetAvailable] = useState("");
  const [askingAmount, setAskingAmount] = useState("");
  const [askingAllocation, setAskingAllocation] = useState("");
  const [offeringAllocation, setOfferingAllocation] = useState("");
  const [askLoading, setAskLoading] = useState(false);
  const [bidLoading, setBidLoading] = useState(false);
  const [currencyPrices, setCurrencyPrices] = useState({});
  const [budgetAvailableUSD, setBudgetAvailableUSD] = useState(0);
  const [askingAmountUSD, setAskingAmountUSD] = useState(0);
  const [askingAmountNative, setAskingAmountNative] = useState(0);
  const [askingAllocationUSD, setAskingAllocationUSD] = useState(0);
  const [OfferingAllocationUSD, setOfferingAllocationUSD] = useState(0);
  const [balances, setBalances] = useState({});
  const [tokenListLoading, setTokenListLoading] = useState(true);
  const [tokenList, setTokenList] = useState(null);
  const [bnbBalance, setBNBBalance] = useState(null);
  const [positionBid, setPositionBid] = useState(0);
  const [positionAsk, setPositionAsk] = useState(0);
  const [dexList, setDexList] = useState(null);
  const [chainSelectedTokenToSell, setChainSelectedTokenToSell] = useState(56);
  const [bidAMMDetails, setBidAMMDetails] = useState({
    chainId: 0,
    ammRouter: "",
    ammName: "",
    ammLogo: "",
    ammFactory: "",
    ammWETH: "",
  });
  const [askAMMDetails, setAskAMMDetails] = useState({
    chainId: 0,
    ammRouter: "",
    ammName: "",
    ammLogo: "",
    ammFactory: "",
    ammWETH: "",
  });

  useEffect(async () => {
    if (globalContext.tokenList !== null) {
      // setBNBBalance({
      //     value: ,
      //     usd: Number(Web3.utils.fromWei(await getBalance(globalContext.walletAddress))) * globalContext.currencyPrices["BNB"]
      // })
      // console.log(bnbBalance)
      await setTokenList(globalContext.tokenList);
      setTokenListLoading(false);
    }
  }, [tokenList, globalContext.tokenList]);

  useEffect(async () => {
    if (globalContext.currencyPrices !== null) {
      setCurrencyPrices(globalContext.currencyPrices);
    }
  }, [globalContext.currencyPrices]);

  useEffect(async () => {
    await fetch(BASE_API_HOST + "/otcRoute/getDexlist")
      .then((res) => res.json())
      .then(async (result) => {
        // console.log(result.result);
        await result.result.push({
          ammFactory: "",
          ammLogo: "",
          ammName: "Others",
          ammRouter: "",
          ammWETH: "",
          chainId: 0,
        });
        await result.result.push({
          ammFactory: "",
          ammLogo: "",
          ammName: "No Dex Available",
          ammRouter: "",
          ammWETH: "",
          chainId: 0,
        });
        setDexList(result.result);
      });
  }, []);

  useEffect(async () => {
    if (tokenToBuy !== null) {
      await setBNBBalance({
        value:
          globalContext.tokenList[tokenToBuy.chainId][ZERO_ADDRESS].mainBalance,
        usd: globalContext.tokenList[tokenToBuy.chainId][ZERO_ADDRESS]
          .mainBalanceUSD,
      });
      await setTokenToPayWith(
        globalContext.tokenList[tokenToBuy.chainId][ZERO_ADDRESS].symbol,
      );
    }
  }, [tokenToBuy]);

  // useEffect(async () => {
  //     if(tokenToSell !== null){
  //         // await setTokenToReceive(globalContext.tokenList[tokenToSell.chainId][ZERO_ADDRESS].symbol)
  //     }
  // },[tokenToSell])

  useEffect(async () => {
    if (tokenToBuy !== null && tokenToBuyDEX !== null) {
      let ammRouter = dexList[tokenToBuyDEX].ammRouter;
      let token = tokenToBuy.token;
      if (dexList[tokenToBuyDEX].ammName !== "No Dex Available") {
        if (Web3.utils.isAddress(ammRouter)) {
          let factoryDetails = await getFactoryDetails(ammRouter, token);
          // console.log(factoryDetails)
          if (factoryDetails.pairAddress !== ZERO_ADDRESS) {
            let price = await getTokenPrice({
              chainId: tokenToBuy.chainId,
              pairContract: factoryDetails.pairAddress,
              WETH: factoryDetails.ammWETH,
              token: tokenToBuy.token,
            });
            setTokenToBuy({ ...tokenToBuy, price });
            setBidAMMDetails({
              ...bidAMMDetails,
              ammRouter,
              ammFactory: factoryDetails.ammFactory,
              ammWETH: factoryDetails.ammWETH,
              chainId: Web3.utils.hexToNumber(await getChainId()),
              pairAddress: factoryDetails.pairAddress,
            });
          } else {
            setTokenToBuyDEX(null);
            notificationConfig.warning(
              "We could not find pair in this router!",
            );
          }
        } else {
          notificationConfig.error("Invalid router address!");
        }
      }
    }
  }, [tokenToBuyDEX]);

  useEffect(async () => {
    if (tokenToSell !== null && tokenToSellDEX !== null) {
      let ammRouter = dexList[tokenToSellDEX].ammRouter;
      let token = tokenToSell.token;
      if (dexList[tokenToSellDEX].ammName !== "No Dex Available") {
        if (Web3.utils.isAddress(ammRouter)) {
          let factoryDetails = await getFactoryDetails(ammRouter, token);
          // console.log(factoryDetails)
          if (factoryDetails.pairAddress !== ZERO_ADDRESS) {
            let price = await getTokenPrice({
              chainId: tokenToSell.chainId,
              pairContract: factoryDetails.pairAddress,
              WETH: factoryDetails.ammWETH,
              token: tokenToSell.token,
            });
            setTokenToSell({ ...tokenToSell, price });
            setAskAMMDetails({
              ...askAMMDetails,
              ammRouter,
              ammFactory: factoryDetails.ammFactory,
              ammWETH: factoryDetails.ammWETH,
              chainId: Web3.utils.hexToNumber(await getChainId()),
              pairAddress: factoryDetails.pairAddress,
            });
          } else {
            setTokenToSellDEX(null);
            notificationConfig.warning(
              "We could not find pair in this router!",
            );
          }
        } else {
          notificationConfig.error("Invalid router address!");
        }
      }
    }
  }, [tokenToSellDEX]);

  const handleInputChange = async (event, name) => {
    const { value } = event.target;
    if (name === "budgetAvailable") {
      if (Number(value) > Number(bnbBalance.value)) {
        notificationConfig.warning("Insufficient balance for listing!");
        return;
      } else {
        setBudgetAvailable(value);
        setBudgetAvailableUSD(
          Number(currencyPrices[tokenToPayWith]) * Number(value),
        );
      }
    } else if (name === "askingAllocation") {
      // if (Number(value) > Number(Number(Web3.utils.fromWei(tokenToBuy.balances[0])))) {
      //     notificationConfig.warning("Insufficient balance for listing!")
      //     return;
      // }
      setAskingAllocation(value);
      setAskingAllocationUSD(Number(tokenToBuy.price) * Number(value));
    } else if (name === "offeringAllocation") {
      if (
        Number(value) >
        Number(Web3.utils.fromWei(tokenToSell.balances[positionAsk]))
      ) {
        notificationConfig.warning("Insufficient balance for listing!");
        return;
      } else {
        setOfferingAllocation(value);
        setAskingAmountNative(Number(askingAmount) / Number(value));
        // setAskingAmountUSD(Number(currencyPrices[tokenToReceive.symbol]) * (Number(askingAmount) / Number(value)))
        setOfferingAllocationUSD(Number(tokenToSell.price) * Number(value));
      }
    } else if (name === "askingAmount" && tokenToReceive !== null) {
      // if(Number(value) > Number(Web3.utils.fromWei(bnbBalance.value))){
      //     notificationConfig.warning("Insufficient balance for listing!")
      //     return;
      // }
      setAskingAmount(value);
      setAskingAmountNative(Number(value) / Number(offeringAllocation));
      setAskingAmountUSD(
        Number(currencyPrices[tokenToReceive.symbol]) *
          (Number(value) / Number(offeringAllocation)),
      );
      // setAskingAmountNative(Number(value) * (Number(currencyPrices[tokenToReceive])/Number(currencyPrices[tokenToPayWith])));
    }
  };

  const handleAMMDetails = async (event, name) => {
    if (name === "bidAMMRouter") {
      let ammRouter = event.target.value;
      let token = tokenToBuy.token;
      if (Web3.utils.isAddress(ammRouter)) {
        let factoryDetails = await getFactoryDetails(ammRouter, token);
        if (factoryDetails.pairAddress !== ZERO_ADDRESS) {
          setBidAMMDetails({
            ...bidAMMDetails,
            ammRouter,
            ammFactory: factoryDetails.ammFactory,
            ammWETH: factoryDetails.ammWETH,
            chainId: Web3.utils.hexToNumber(await getChainId()),
            pairAddress: factoryDetails.pairAddress,
          });
        } else {
          notificationConfig.warning("We could not find pair!");
        }
      } else {
        notificationConfig.error("Invalid router address!");
      }
    } else if (name === "askAMMRouter") {
      let ammRouter = event.target.value;
      let token = tokenToSell.token;
      if (Web3.utils.isAddress(ammRouter)) {
        let factoryDetails = await getFactoryDetails(ammRouter, token);
        if (factoryDetails.pairAddress !== ZERO_ADDRESS) {
          setAskAMMDetails({
            ...askAMMDetails,
            ammRouter,
            ammFactory: factoryDetails.ammFactory,
            ammWETH: factoryDetails.ammWETH,
            chainId: Web3.utils.hexToNumber(await getChainId()),
            pairAddress: factoryDetails.pairAddress,
          });
        } else {
          notificationConfig.warning("We could not find pair!");
        }
      } else {
        notificationConfig.error("Invalid router address!");
      }
    }
  };

  const floatOnly = (event) => {
    if (event.shiftKey === true) event.preventDefault();

    var code = event.keyCode;

    if (
      (code >= 48 && code <= 57) ||
      (code >= 96 && code <= 105) ||
      code === 8 ||
      code === 9 ||
      code === 37 ||
      code === 39 ||
      code === 46 ||
      code === 190 ||
      code === 110
    ) {
      // allowed characters
    } else event.preventDefault();

    if (
      event.target.value.indexOf(".") !== -1 &&
      (code === 190 || code === 110)
    )
      event.preventDefault();
  };

  const handleBid = async (network) => {
    let newNetworkId = localStorage.getItem("chainId");

    if (
      network === "Ethereum" &&
      (newNetworkId === "0x61" || newNetworkId === "0x38")
    ) {
      notificationConfig.warning("Change metamask network to Ethereum!");
      return;
    } else if (
      network === "BSC" &&
      (newNetworkId === "0x2A" || newNetworkId === "0x1")
    ) {
      notificationConfig.warning("Change metamask network to Binance!");
      return;
    }

    if (
      askingAllocation === "" ||
      askingAllocation === "0" ||
      askingAllocation <= 0
    ) {
      notificationConfig.info("Please Enter Valid Token Amount");
      return 0;
    }

    if (tokenToBuy === null) {
      notificationConfig.info("Please select token first");
      return 0;
    }

    if (
      tokenToBuyDEX &&
      dexList[tokenToBuyDEX].ammName !== "No Dex Available"
    ) {
      if (bidAMMDetails.ammName === "Others") {
        if (bidAMMDetails.ammRouter === "") {
          notificationConfig.info("Please select valid DEX address");
          return 0;
        }
        if (bidAMMDetails.ammName === "") {
          notificationConfig.info("Please enter valid DEX name");
          return 0;
        }
        if (bidAMMDetails.ammLogo === "") {
          notificationConfig.info("Please enter valid DEX logo url");
          return 0;
        }
      } else {
        if (bidAMMDetails.ammRouter === "") {
          notificationConfig.info("Please select valid DEX");
          return 0;
        }
      }
    }

    // if (Number(transferTokensAmount) > (Number(balances[0]) / 10 ** 18)) {
    //     console.log('============');
    //     console.log(Number(transferTokensAmount));
    //     console.log(balances[0]);
    //     console.log((Number(balances[0]) / 10 ** 18));
    //     notificationConfig.info("Insufficient Balance For Transfer");
    //     return 0;
    // }

    setBidLoading(true);

    let otcBuyTokenData = {};

    // console.log(Web3.utils.toWei(askingAllocation))
    otcBuyTokenData["wantToken"] = tokenToBuy.token;
    otcBuyTokenData["wantTokenAmount"] = Web3.utils.toWei(askingAllocation);
    otcBuyTokenData["payableAmount"] = Web3.utils.toWei(budgetAvailable);
    otcBuyTokenData["position"] = positionBid;

    await otcBuyToken(
      otcBuyTokenData,
      async (hash) => {
        if (
          tokenToBuyDEX &&
          dexList[tokenToBuyDEX].ammName !== "No Dex Available"
        ) {
          // console.log({ transactionHash: hash, pairAddress: bidAMMDetails.pairAddress})
          await addPairAddress({
            transactionHash: hash,
            pairAddress: bidAMMDetails.pairAddress,
          });
          if (dexList[tokenToBuyDEX].ammName === "Others") {
            await addDEX(bidAMMDetails);
          }
        }
      },
      async (receipt) => {
        console.log("receipt");
        console.log(receipt);
        if (tokenToBuyDEX && dexList[tokenToBuyDEX].ammName === "Others") {
          await addDEX(bidAMMDetails);
        }

        notificationConfig.success("Buy Request Listed Successfully!");
        setBidLoading(false);
      },
      async (error) => {
        setBidLoading(false);
        if (error.code === 4001) {
          notificationConfig.error("Transaction rejected!");
        }
      },
    );
  };

  const handleAsk = async () => {
    let newNetworkId = localStorage.getItem("chainId");

    if (
      offeringAllocation === "" ||
      offeringAllocation === "0" ||
      offeringAllocation <= 0
    ) {
      notificationConfig.info("Please Enter Valid Token Amount");
      return 0;
    }

    if (tokenToSell === null) {
      notificationConfig.info("Please select token first");
      return 0;
    }

    if (tokenToReceive === null) {
      notificationConfig.info("Please select receive token first");
      return 0;
    }

    if (
      tokenToSellDEX &&
      dexList[tokenToSellDEX].ammName !== "No Dex Available"
    ) {
      if (askAMMDetails.ammName === "Others") {
        if (askAMMDetails.ammRouter === "") {
          notificationConfig.info("Please select valid DEX address");
          return 0;
        }
        if (askAMMDetails.ammName === "") {
          notificationConfig.info("Please enter valid DEX name");
          return 0;
        }
        if (askAMMDetails.ammLogo === "") {
          notificationConfig.info("Please enter valid DEX logo url");
          return 0;
        }
      } else if (askAMMDetails.ammRouter === "") {
        notificationConfig.info("Please select valid DEX");
        return 0;
      }
    }

    // if (Number(transferTokensAmount) > (Number(balances[0]) / 10 ** 18)) {
    //     console.log('============');
    //     console.log(Number(transferTokensAmount));
    //     console.log(balances[0]);
    //     console.log((Number(balances[0]) / 10 ** 18));
    //     notificationConfig.info("Insufficient Balance For Transfer");
    //     return 0;
    // }

    setAskLoading(true);

    let otcTransferTokenData = {};

    console.log(Web3.utils.toWei(offeringAllocation));
    otcTransferTokenData["buyer"] = ZERO_ADDRESS;
    otcTransferTokenData["tokenSell"] = tokenToSell.token;
    otcTransferTokenData["sellAmount"] = Web3.utils.toWei(offeringAllocation);
    otcTransferTokenData["wantToken"] = tokenToReceive.token;
    otcTransferTokenData["router"] = ZERO_ADDRESS;
    otcTransferTokenData["value"] = Web3.utils.toWei(askingAmount);
    otcTransferTokenData["confirmatory"] = ZERO_ADDRESS;
    otcTransferTokenData["transferType"] = "transferAsSale";
    otcTransferTokenData["position"] = positionAsk;

    await otcTransferToken(
      otcTransferTokenData,
      async (hash) => {
        if (
          tokenToSellDEX &&
          dexList[tokenToSellDEX].ammName !== "No Dex Available"
        ) {
          await addPairAddress({
            transactionHash: hash,
            pairAddress: askAMMDetails.pairAddress,
          });
          if (dexList[tokenToSellDEX].ammName === "Others") {
            await addDEX(askAMMDetails);
          }
        }
      },
      async (receipt) => {
        console.log("receipt");
        console.log(receipt);
        if (tokenToSellDEX && dexList[tokenToSellDEX].ammName === "Others") {
          await addDEX(askAMMDetails);
        }
        notificationConfig.success("Ask Request Listed Successfully!");
        setAskLoading(false);
      },
      async (error) => {
        setAskLoading(false);
        if (error.code === 4001) {
          notificationConfig.error("Transaction rejected!");
        }
      },
    );
  };

  const _onChangeRadio = async (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value === "true" ? true : false,
    });
  };

  const onToggle = (indexNew) => setIndex(index === indexNew ? null : indexNew);

  const onToggle02 = (dropDownNew) =>
    setDropDown(dropDown === dropDownNew ? null : dropDownNew);

  const exchange = async () => {
    const {
      tokenToPayWith,
      tokenToBuy,
      budgetAvailable,
      askingAllocation,
      budgetAvailableUSD,
      askingAllocationUSD,
    } = this.state;
    this.setState({
      tokenToPayWith: tokenToBuy,
      tokenToBuy: tokenToPayWith,
      budgetAvailable: askingAllocation,
      askingAllocation: budgetAvailable,
      budgetAvailableUSD: askingAllocationUSD,
      askingAllocationUSD: budgetAvailableUSD,
    });
  };

  return (
    <Modal isOpen={props.isOpen} dismiss={props.dismiss}>
      <BuyRPopupContainer>
        {/* <TabLinkbx> 
                    <button className={(index === 1 ? "active" : "")} onClick={() => this.onToggle(1)}>List buy request</button>
                    <button className={(index === 2 ? "active" : "")} onClick={() => this.onToggle(2)}>List sell request</button>
               </TabLinkbx> */}
        <>
          <PopTitle01>List OTC Request</PopTitle01>
          <TabNav>
            <a
              className={`tab-Link tab01 ${getActiveClass(1, "active")}`}
              onClick={() => toggleTab(1)}
            >
              List Your Buy Offer{" "}
              <span
                data-html="true"
                data-tip="Set price that does not change"
                className="helpIco"
                currentitem="false"
              >
                <i className="fas fa-question-circle"></i>
              </span>
            </a>
            <a
              className={`tab-Link tab02 ${getActiveClass(2, "active")}`}
              onClick={() => toggleTab(2)}
            >
              List Your Sell Offer{" "}
              <span
                data-html="true"
                data-tip="Variable percentage based on the token’s current market value"
                className="helpIco"
                currentitem="false"
              >
                <i className="fas fa-question-circle"></i>
              </span>
            </a>
          </TabNav>

          {getActiveClass(
            1,
            <TabContainer>
              <FormMBX01>
                <FormSbxLabel>Token to pay with</FormSbxLabel>
                <FormSbxContent>
                  <DDMenu onClick={() => onToggle02(1)}>
                    {tokenToPayWith}
                    <div className="DDContainer">
                      <Collapse
                        className={
                          "collapse " + (dropDown === 1 ? "active" : "")
                        }
                        isOpen={dropDown === 1}
                        onChange={({ state }) => {}}
                        onInit={({ state }) => {}}
                      >
                        <div className="DDSubContainer">
                          <button>{tokenToPayWith}</button>
                        </div>
                      </Collapse>
                    </div>
                  </DDMenu>
                  <span className="infotxt" style={{ display: "flex" }}>
                    [Current market value $
                    {currencyPrices ? currencyPrices[tokenToPayWith] : 0}]
                  </span>
                  {/* <DDMenuSmall onClick={() => onToggle02(2)}>
                                                {tokenToBuy !== null ? tokenToBuy.symbol : "Select DEX..."}
                                                <div className="DDContainer">
                                                    <Collapse className={"collapse " + (dropDown === 2 ? "active" : "")}
                                                        isOpen={dropDown === 2}
                                                        onChange={({ state }) => { }}
                                                        onInit={({ state }) => { }}>
                                                        <div className="DDSubContainer">
                                                            {
                                                                !tokenListLoading ?
                                                                    Object.entries(dexList).map((ele) => {
                                                                        return <button onClick={() => { setTokenToBuy(ele[1]); } }>{ele[1].ammName}</button>
                                                                    })
                                                                    : <ReactLoaderSpinner type="Bars" color="#fff" height={20} width={40} /> 
                                                            }
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            </DDMenuSmall> */}
                </FormSbxContent>
              </FormMBX01>
              <FormMBX01>
                <FormSbxLabel>Budget available</FormSbxLabel>

                <FormSbxContent>
                  <DDMenu className="formBox">
                    <input
                      type="text"
                      name="budgetAvailable"
                      onChange={(e) => handleInputChange(e, "budgetAvailable")}
                      value={budgetAvailable}
                      onKeyDown={(e) => floatOnly(e)}
                      autoComplete="off"
                    />
                  </DDMenu>
                  <div className="InputInfo">
                    <span className="infotxt">
                      Balance{" "}
                      {bnbBalance !== null
                        ? Number(bnbBalance.value).toFixed(6)
                        : 0}{" "}
                      {tokenToPayWith}
                    </span>
                    <span className="infotxt">
                      [$
                      {bnbBalance !== null
                        ? Number(bnbBalance.usd).toFixed(6)
                        : 0}
                      ]
                    </span>
                  </div>
                  <div className="flexBox">
                    <DragMBX>
                      <div className="dragorInput">
                        {/* <InputRange
                                                maxValue={bnbBalance !== null ? Web3.utils.fromWei((bnbBalance.value * 1000000).toString()) : 0}
                                                minValue={0}
                                                value={budgetAvailable}
                                                formatLabel=""
                                                onChange={value => { handleInputChange({ target: { value: value } },"budgetAvailable") }} 
                                                /> */}
                      </div>
                    </DragMBX>
                    {/* <input className='SlideValue' type="text" name="budgetAvailable" onChange={(e)=>handleInputChange(e,"budgetAvailable")} value={budgetAvailable} onKeyDown={(e)=>floatOnly(e)} autoComplete="off" /> */}
                  </div>
                </FormSbxContent>
              </FormMBX01>
              <SwapLine>
                <span>
                  <a className="swap-icon">
                    <img src={SwapIcon} />
                  </a>
                </span>
              </SwapLine>
              <TabNav>
                <a
                  className={`tab-Link ${getActiveClass(1, "active")}`}
                  onClick={() => toggleTab(3)}
                >
                  Fixed Price{" "}
                  <span
                    data-html="true"
                    data-tip="Set price that does not change"
                    className="helpIco"
                    currentitem="false"
                  >
                    <i className="fas fa-question-circle"></i>
                  </span>
                </a>
                <a
                  className={`tab-Link ${getActiveClass(4, "active")}`}
                  onClick={() => toggleTab(4)}
                >
                  Market Price +-%{" "}
                  <span
                    data-html="true"
                    data-tip="Variable percentage based on the token’s current market value"
                    className="helpIco"
                    currentitem="false"
                  >
                    <i className="fas fa-question-circle"></i>
                  </span>
                </a>
              </TabNav>
              <TabContainer>
                <FormMBX01>
                  <FormSbxLabel>
                    Token to buy{" "}
                    <span
                      data-html="true"
                      data-tip="Token to receive in return in the OTC deal"
                      className="helpIco"
                      currentitem="false"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </FormSbxLabel>
                  <FormSbxContent>
                    <DDMenu onClick={() => onToggle02(3)}>
                      {tokenToBuy !== null
                        ? tokenToBuy.symbol
                        : "Select Token..."}
                      <div className="DDContainer">
                        <Collapse
                          className={
                            "collapse " + (dropDown === 3 ? "active" : "")
                          }
                          isOpen={dropDown === 3}
                          onChange={({ state }) => {}}
                          onInit={({ state }) => {}}
                        >
                          <div className="DDSubContainer">
                            {/* <button>{tokenToBuy}</button> */}
                            {!tokenListLoading ? (
                              Object.entries(tokenList).map(
                                (pairWiseList, key) => {
                                  return Object.entries(pairWiseList[1]).map(
                                    (ele, keyin) => {
                                      if (ele[1].dumperShieldToken)
                                        return (
                                          <button
                                            onClick={() => {
                                              setTokenToBuy(ele[1]);
                                            }}
                                          >
                                            {ele[1].symbol}
                                          </button>
                                        );
                                    },
                                  );
                                },
                              )
                            ) : (
                              <ReactLoaderSpinner
                                type="Bars"
                                color="#fff"
                                height={20}
                                width={40}
                              />
                            )}
                          </div>
                        </Collapse>
                      </div>
                    </DDMenu>
                    {tokenToBuy !== null ? (
                      <span className="infotxt" style={{ display: "flex" }}>
                        [Current market price
                        <DDMenuSmall onClick={() => onToggle02(4)}>
                          {tokenToBuyDEX !== null
                            ? dexList[tokenToBuyDEX].ammName
                            : "Select DEX..."}
                          <div className="DDContainer">
                            <Collapse
                              className={
                                "collapse " + (dropDown === 4 ? "active" : "")
                              }
                              isOpen={dropDown === 4}
                              onChange={({ state }) => {}}
                              onInit={({ state }) => {}}
                            >
                              <div className="DDSubContainer">
                                {!tokenListLoading && dexList !== null ? (
                                  Object.entries(dexList).map((ele, key) => {
                                    return (
                                      <span style={{ display: "flex" }}>
                                        <img
                                          height="12px"
                                          width="25px"
                                          src={ele[1].ammLogo}
                                        ></img>
                                        <button
                                          onClick={() => {
                                            setTokenToBuyDEX(key);
                                          }}
                                        >
                                          {ele[1].ammName}
                                        </button>
                                      </span>
                                    );
                                  })
                                ) : dexList === null ? null : (
                                  <ReactLoaderSpinner
                                    type="Bars"
                                    color="#fff"
                                    height={20}
                                    width={40}
                                  />
                                )}
                              </div>
                            </Collapse>
                          </div>
                        </DDMenuSmall>
                        ${Number(tokenToBuy.price).toFixed(6)}]
                      </span>
                    ) : null}
                  </FormSbxContent>
                </FormMBX01>
              </TabContainer>
              {tokenToBuyDEX !== null ? (
                dexList[tokenToBuyDEX].ammName === "Others" ? (
                  <>
                    <FormMBX01>
                      <FormSbxLabel>
                        Enter AMM Service router Address{" "}
                        <span
                          data-html="true"
                          data-tip="Choose the DEX to track the average market value on"
                          className="helpIco"
                          currentitem="false"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </FormSbxLabel>
                      <FormSbxContent>
                        <DDMenu className="formBox">
                          <input
                            type="text"
                            name="bidAMMRouter"
                            value={bidAMMDetails.ammRouter}
                            onChange={(e) =>
                              handleAMMDetails(e, "bidAMMRouter")
                            }
                            autoComplete="off"
                          />
                        </DDMenu>
                      </FormSbxContent>
                    </FormMBX01>
                    <FormMBX01>
                      <FormSbxLabel>
                        Enter AMM Name{" "}
                        <span
                          data-html="true"
                          data-tip="Choose the DEX to track the average market value on"
                          className="helpIco"
                          currentitem="false"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </FormSbxLabel>
                      <FormSbxContent>
                        <DDMenu className="formBox">
                          <input
                            type="text"
                            name="bidAMMName"
                            onChange={(e) =>
                              setBidAMMDetails({
                                ...bidAMMDetails,
                                ammName: e.target.value,
                              })
                            }
                            value={bidAMMDetails.ammName}
                            autoComplete="off"
                          />
                        </DDMenu>
                      </FormSbxContent>
                    </FormMBX01>
                    <FormMBX01>
                      <FormSbxLabel>
                        Enter AMM Logo URL{" "}
                        <span
                          data-html="true"
                          data-tip="Choose the DEX to track the average market value on"
                          className="helpIco"
                          currentitem="false"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </FormSbxLabel>
                      <FormSbxContent>
                        <DDMenu className="formBox">
                          <input
                            type="text"
                            name="bidAMMLogo"
                            onChange={(e) =>
                              setBidAMMDetails({
                                ...bidAMMDetails,
                                ammLogo: e.target.value,
                              })
                            }
                            value={bidAMMDetails.ammLogo}
                            autoComplete="off"
                          />
                        </DDMenu>
                      </FormSbxContent>
                    </FormMBX01>
                  </>
                ) : null
              ) : null}
              <FormMBX01>
                <FormSbxLabel>
                  Position
                  <span
                    data-tip="Tax protection requests must be verified by other Dumper Shield users. Consult a local accountant, this is not tax advice."
                    className="helpIco"
                  >
                    <i className="fas fa-question-circle"></i>
                  </span>
                </FormSbxLabel>
                <FormSbxContent>
                  <DDMenu className="noBorder ver2">
                    <div
                      className="md-radio md-radio-inline"
                      style={{ width: "30%" }}
                    >
                      <input
                        type="radio"
                        defaultChecked
                        id="radioty150"
                        name="positionBid"
                        value={positionBid === 0}
                        onChange={(e) => setPositionBid(0)}
                      />
                      <label htmlFor="radioty150">
                        1st
                        <span data-tip="1st Position" className="helpIco">
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </label>
                    </div>
                    <div
                      className="md-radio md-radio-inline"
                      style={{ width: "30%" }}
                    >
                      <input
                        type="radio"
                        id="radioty151"
                        name="positionBid"
                        value={positionBid === 1}
                        onChange={(e) => setPositionBid(1)}
                      />
                      <label htmlFor="radioty151">
                        2nd
                        <span data-tip="2nd Position" className="helpIco">
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </label>
                    </div>
                    <div
                      className="md-radio md-radio-inline"
                      style={{ width: "30%" }}
                    >
                      <input
                        type="radio"
                        id="radioty152"
                        name="positionBid"
                        value={positionBid === 2}
                        onChange={(e) => setPositionBid(2)}
                      />
                      <label htmlFor="radioty152">
                        3rd
                        <span data-tip="3rd Position" className="helpIco">
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </label>
                    </div>
                  </DDMenu>
                </FormSbxContent>
              </FormMBX01>
              {/* <FormMBX01>
                                    <FormSbxLabel>Amount of tokens</FormSbxLabel>
                                    <FormSbxContent >
                                        <DDMenu className="formBox">
                                            <input type="text" name="budgetAvailable" onChange={(e)=>handleInputChange(e,"budgetAvailable")} value={budgetAvailable} onKeyDown={(e)=>floatOnly(e)} autoComplete="off" />
                                        </DDMenu>
                                            <span className="infotxt">[Current market value  $1,000 / 1:10,000]</span>
                                        
                                    </FormSbxContent>
                                </FormMBX01> */}

              <FormMBX01>
                <FormSbxLabel>
                  Asking allocation{" "}
                  <span
                    data-html="true"
                    data-tip="Choose the DEX to track the average market value on"
                    className="helpIco"
                    currentitem="false"
                  >
                    <i className="fas fa-question-circle"></i>
                  </span>
                </FormSbxLabel>
                <FormSbxContent>
                  <DDMenu className="formBox">
                    <input
                      type="text"
                      name="askingAllocation"
                      onChange={(e) => handleInputChange(e, "askingAllocation")}
                      value={askingAllocation}
                      onKeyDown={(e) => floatOnly(e)}
                      autoComplete="off"
                    />
                  </DDMenu>
                  {tokenToBuy !== null ? (
                    <span className="infotxt">
                      [Bid Price: 1{" "}
                      {tokenToBuy.symbol +
                        " <> " +
                        Number(tokenToBuy.price).toFixed(4)}
                      ]
                    </span>
                  ) : null}
                </FormSbxContent>
              </FormMBX01>

              {/* <TableMain className='height1'>
                                    <h3>Similar OTC orders that match the listing request</h3>
                                    <table className='border-cell' width="100%" border="0" cellSpacing="0" cellPadding="20" >
                                        <thead>
                                            <tr>
                                                <th align="left" valign="middle" scope="col">Token Pairs</th>
                                                <th align="left" valign="middle" scope="col">Vesting Due Date</th>
                                                <th align="left" valign="middle" scope="col">Supply Available</th>
                                                <th align="left" valign="middle" scope="col">Market Price </th>
                                                <th align="left" valign="middle" scope="col">Asking Amount <span data-html="true" data-tip="test" className="helpIco" currentitem="false"><i className="fas fa-question-circle"></i></span></th>
                                                <th align="left" valign="middle" scope="col">Competitive Value <span data-html="true" data-tip="test" className="helpIco" currentitem="false"><i className="fas fa-question-circle"></i></span></th>
                                                <th align="center" valign="middle" scope="col">OTC Price</th>
                                                <th align="center" valign="center" scope="col">Buy Opportunity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='color-green'>SMART/BNB</td>
                                                <td>Apr 2, 2022</td>
                                                <td>291</td>
                                                <td data-tip="0.2000923818283628361 BNB">0.2000 BNB <Block className='color-dull'>[$0.01986]</Block></td>
                                                <td data-tip="0.2000923818283628361 BNB">0.1991 BNB <Block className='color-dull'>[$0.02385]</Block></td>
                                                <td className='color-red'>-$1 (-0.94%)</td>
                                                <td data-tip="0.2000923818283628361 BNB">0.1991 BNB <Block className='color-dull'>[$0.02385]</Block></td>
                                                <td><a className='btn01'>Buy</a></td>
                                            </tr>
                                            <tr>
                                                <td className='color-green'>SMART/BNB</td>
                                                <td>Apr 2, 2022</td>
                                                <td>291</td>
                                                <td data-tip="0.2000923818283628361 BNB">0.2000 BNB <Block className='color-dull'>[$0.01986]</Block></td>
                                                <td data-tip="0.2000923818283628361 BNB">0.1991 BNB <Block className='color-dull'>[$0.02385]</Block></td>
                                                <td className='color-green'>-$1 (-0.94%)</td>
                                                <td data-tip="0.2000923818283628361 BNB">0.1991 BNB <Block className='color-dull'>[$0.02385]</Block></td>
                                                <td><a className='btn01'>Buy</a></td>
                                            </tr>
                                            <tr>
                                                <td className='color-green'>SMART/BNB</td>
                                                <td>Apr 2, 2022</td>
                                                <td>291</td>
                                                <td data-tip="0.2000923818283628361 BNB">0.2000 BNB <Block className='color-dull'>[$0.01986]</Block></td>
                                                <td data-tip="0.2000923818283628361 BNB">0.1991 BNB <Block className='color-dull'>[$0.02385]</Block></td>
                                                <td className='color-red'>-$1 (-0.94%)</td>
                                                <td data-tip="0.2000923818283628361 BNB">0.1991 BNB <Block className='color-dull'>[$0.02385]</Block></td>
                                                <td><a className='btn01'>Buy</a></td>
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                </TableMain> */}
              <FormBTNBX>
                {tokenToBuy !== null &&
                Web3.utils.toHex(tokenToBuy.chainId) !==
                  globalContext.chainId ? (
                  <button
                    className="PlaOrBTN01"
                    onClick={() => {
                      changeNetwork(Web3.utils.toHex(tokenToBuy.chainId));
                    }}
                  >
                    CHANGE NETWORK
                  </button>
                ) : bidLoading ? (
                  <button className="PlaOrBTN01">
                    <ReactLoaderSpinner
                      type="ThreeDots"
                      color="#fff"
                      height={15}
                      width={80}
                    />
                  </button>
                ) : (
                  <button
                    className="PlaOrBTN01"
                    onClick={() => handleBid("BSC")}
                  >
                    LIST YOUR BUY OFFER
                  </button>
                )}
                <span>Listed OTC request is 10,000 SMART : 1 ETH</span>
              </FormBTNBX>
            </TabContainer>,
          )}

          {getActiveClass(
            2,
            <TabContainer>
              <FormMBX01>
                <FormSbxLabel>Token to sell</FormSbxLabel>
                <FormSbxContent>
                  <DropMain>
                    <DDMenu onClick={() => onToggle02(5)}>
                      {tokenToSell !== null
                        ? tokenToSell.symbol
                        : "Select Token..."}
                      <div className="DDContainer">
                        <Collapse
                          className={
                            "collapse " + (dropDown === 5 ? "active" : "")
                          }
                          isOpen={dropDown === 5}
                          onChange={({ state }) => {}}
                          onInit={({ state }) => {}}
                        >
                          <div className="DDSubContainer">
                            {/* <button>{tokenToSell}</button> */}
                            {!tokenListLoading ? (
                              Object.entries(tokenList).map(
                                (pairWiseList, key) => {
                                  return Object.entries(pairWiseList[1]).map(
                                    (ele, keyin) => {
                                      if (
                                        ele[1].canTransfer &&
                                        ele[1].chainId ===
                                          chainSelectedTokenToSell
                                      )
                                        return (
                                          <button
                                            onClick={() => {
                                              setTokenToSell(ele[1]);
                                            }}
                                          >
                                            {ele[1].symbol}
                                          </button>
                                        );
                                    },
                                  );
                                },
                              )
                            ) : (
                              <ReactLoaderSpinner
                                type="Bars"
                                color="#fff"
                                height={20}
                                width={40}
                              />
                            )}
                          </div>
                        </Collapse>
                      </div>
                    </DDMenu>
                    <DDMenu className="xs" onClick={() => onToggle02(8)}>
                      <img
                        src={nativeTokenList[chainSelectedTokenToSell].logoURI}
                        alt=""
                      />
                      <div className="DDContainer">
                        <Collapse
                          className={
                            "collapse " + (dropDown === 8 ? "active" : "")
                          }
                          isOpen={dropDown === 8}
                        >
                          <div className="DDSubContainer">
                            {ACTIVE_CHAINS_DECIMAL.map((ele) => {
                              return (
                                <button
                                  onClick={() => {
                                    setChainSelectedTokenToSell(ele);
                                    setTokenToSell(null);
                                    setTokenToReceive(null);
                                  }}
                                >
                                  <img
                                    src={nativeTokenList[ele].logoURI}
                                    alt="chain"
                                  ></img>
                                </button>
                              );
                            })}
                          </div>
                        </Collapse>
                      </div>
                    </DDMenu>
                  </DropMain>
                  {tokenToSell !== null ? (
                    <span className="infotxt" style={{ display: "flex" }}>
                      [Current market price
                      <DDMenuSmall onClick={() => onToggle02(6)}>
                        {tokenToSellDEX !== null
                          ? dexList[tokenToSellDEX].ammName
                          : "Select DEX..."}
                        <div className="DDContainer">
                          <Collapse
                            className={
                              "collapse " + (dropDown === 6 ? "active" : "")
                            }
                            isOpen={dropDown === 6}
                            onChange={({ state }) => {}}
                            onInit={({ state }) => {}}
                          >
                            <div className="DDSubContainer">
                              {!tokenListLoading && dexList !== null ? (
                                Object.entries(dexList).map((ele, key) => {
                                  return (
                                    <span style={{ display: "flex" }}>
                                      <img
                                        height="12px"
                                        width="25px"
                                        src={ele[1].ammLogo}
                                      ></img>
                                      <button
                                        onClick={() => {
                                          setTokenToSellDEX(key);
                                        }}
                                      >
                                        {ele[1].ammName}
                                      </button>
                                    </span>
                                  );
                                })
                              ) : dexList === null ? null : (
                                <ReactLoaderSpinner
                                  type="Bars"
                                  color="#fff"
                                  height={20}
                                  width={40}
                                />
                              )}
                            </div>
                          </Collapse>
                        </div>
                      </DDMenuSmall>
                      ${Number(tokenToSell.price).toFixed(6)}]
                    </span>
                  ) : null}
                </FormSbxContent>
              </FormMBX01>
              {tokenToSellDEX !== null ? (
                dexList[tokenToSellDEX].ammName === "Others" ? (
                  <>
                    <FormMBX01>
                      <FormSbxLabel>
                        Enter AMM Service router Address{" "}
                        <span
                          data-html="true"
                          data-tip="Choose the DEX to track the average market value on"
                          className="helpIco"
                          currentitem="false"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </FormSbxLabel>
                      <FormSbxContent>
                        <DDMenu className="formBox">
                          <input
                            type="text"
                            name="askAMMRouter"
                            onChange={(e) =>
                              handleAMMDetails(e, "askAMMRouter")
                            }
                            value={askAMMDetails.ammRouter}
                            onKeyDown={(e) =>
                              Web3.utils.isAddress(e.target.value)
                            }
                            autoComplete="off"
                          />
                        </DDMenu>
                      </FormSbxContent>
                    </FormMBX01>
                    <FormMBX01>
                      <FormSbxLabel>
                        Enter AMM Name{" "}
                        <span
                          data-html="true"
                          data-tip="Choose the DEX to track the average market value on"
                          className="helpIco"
                          currentitem="false"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </FormSbxLabel>
                      <FormSbxContent>
                        <DDMenu className="formBox">
                          <input
                            type="text"
                            name="askAMMName"
                            onChange={(e) =>
                              setAskAMMDetails({ ammName: e.target.value })
                            }
                            value={askAMMDetails.ammName}
                            autoComplete="off"
                          />
                        </DDMenu>
                      </FormSbxContent>
                    </FormMBX01>
                    <FormMBX01>
                      <FormSbxLabel>
                        Enter AMM Logo URL{" "}
                        <span
                          data-html="true"
                          data-tip="Choose the DEX to track the average market value on"
                          className="helpIco"
                          currentitem="false"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </FormSbxLabel>
                      <FormSbxContent>
                        <DDMenu className="formBox">
                          <input
                            type="text"
                            name="askAMMLogo"
                            onChange={(e) =>
                              setAskAMMDetails({ ammLogo: e.target.value })
                            }
                            value={askAMMDetails.ammLogo}
                            autoComplete="off"
                          />
                        </DDMenu>
                      </FormSbxContent>
                    </FormMBX01>
                  </>
                ) : null
              ) : null}
              <FormMBX01>
                <FormSbxLabel>
                  Position
                  <span
                    data-tip="Tax protection requests must be verified by other Dumper Shield users. Consult a local accountant, this is not tax advice."
                    className="helpIco"
                  >
                    <i className="fas fa-question-circle"></i>
                  </span>
                </FormSbxLabel>
                <FormSbxContent>
                  <DDMenu className="noBorder ver2 redCol">
                    <div
                      className="md-radio md-radio-inline"
                      style={{ width: "30%" }}
                    >
                      <input
                        type="radio"
                        defaultChecked
                        id="radioty153"
                        name="positionAsk"
                        value={positionAsk === 0}
                        onChange={(e) => setPositionAsk(0)}
                      />
                      <label htmlFor="radioty153">
                        1st
                        <span data-tip="1st Position" className="helpIco">
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </label>
                    </div>
                    <div
                      className="md-radio md-radio-inline"
                      style={{ width: "30%" }}
                    >
                      <input
                        type="radio"
                        id="radioty154"
                        name="positionAsk"
                        value={positionAsk === 1}
                        onChange={(e) => setPositionAsk(1)}
                      />
                      <label htmlFor="radioty154">
                        2nd
                        <span data-tip="2nd Position" className="helpIco">
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </label>
                    </div>
                    <div
                      className="md-radio md-radio-inline"
                      style={{ width: "30%" }}
                    >
                      <input
                        type="radio"
                        id="radioty155"
                        name="positionAsk"
                        value={positionAsk === 2}
                        onChange={(e) => setPositionAsk(2)}
                      />
                      <label htmlFor="radioty155">
                        3rd
                        <span data-tip="3rd Position" className="helpIco">
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </label>
                    </div>
                  </DDMenu>
                </FormSbxContent>
              </FormMBX01>
              <FormMBX01>
                <FormSbxLabel>Offering allocation</FormSbxLabel>
                <FormSbxContent>
                  <DDMenu className="formBox">
                    <input
                      type="text"
                      name="offeringAllocation"
                      onChange={(e) =>
                        handleInputChange(e, "offeringAllocation")
                      }
                      value={offeringAllocation}
                      onKeyDown={(e) => floatOnly(e)}
                      autoComplete="off"
                    />
                  </DDMenu>
                  <div className="InputInfo">
                    <span
                      className="infotxt"
                      onClick={() => {
                        setOfferingAllocation(
                          Web3.utils.fromWei(tokenToSell.balances[positionAsk]),
                        );
                      }}
                    >
                      Balance{" "}
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {tokenToSell !== null
                          ? Number(
                              Web3.utils.fromWei(
                                tokenToSell.balances[positionAsk],
                              ),
                            ).toFixed(6)
                          : 0}
                      </span>
                    </span>
                    <span className="infotxt">
                      [${Number(OfferingAllocationUSD).toFixed(6)}]
                    </span>
                  </div>
                  <div className="flexBox">
                    {/* <DragMBX>
                                        <div className="dragorInput">
                                        <InputRange
                                            maxValue="100"
                                            minValue="01"
                                            value="0"
                                            formatLabel=""
                                            />
                                        </div>
                                    </DragMBX>
                                    <input type="text" value={150} className='SlideValue' name='' /> */}
                  </div>
                </FormSbxContent>
              </FormMBX01>
              <SwapLine>
                <span>
                  <a className="swap-icon">
                    <img src={SwapIconred} />
                  </a>
                </span>
              </SwapLine>
              <TabNav>
                <a
                  className={`tab-Link tab02 ${getActiveClass(2, "active")}`}
                  onClick={() => toggleTab(3)}
                >
                  Fixed Price{" "}
                  <span
                    data-html="true"
                    data-tip="Set price that does not change"
                    className="helpIco"
                    currentitem="false"
                  >
                    <i className="fas fa-question-circle"></i>
                  </span>
                </a>
                <a
                  className={`tab-Link ${getActiveClass(4, "active")}`}
                  onClick={() => toggleTab(4)}
                >
                  Market Price +-%{" "}
                  <span
                    data-html="true"
                    data-tip="Variable percentage based on the token’s current market value"
                    className="helpIco"
                    currentitem="false"
                  >
                    <i className="fas fa-question-circle"></i>
                  </span>
                </a>
              </TabNav>
              <TabContainer>
                <FormMBX01>
                  <FormSbxLabel>
                    Token to receive{" "}
                    <span
                      data-html="true"
                      data-tip="Token to receive in return in the OTC deal"
                      className="helpIco"
                      currentitem="false"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </FormSbxLabel>
                  <FormSbxContent>
                    <DDMenu onClick={() => onToggle02(7)}>
                      {tokenToReceive !== null
                        ? tokenToReceive.symbol
                        : "Select token..."}
                      <div className="DDContainer">
                        <Collapse
                          className={
                            "collapse " + (dropDown === 7 ? "active" : "")
                          }
                          isOpen={dropDown === 7}
                          onChange={({ state }) => {}}
                          onInit={({ state }) => {}}
                        >
                          <div className="DDSubContainer">
                            {Object.entries(
                              supportedStableCoins[chainSelectedTokenToSell],
                            ).map((ele, key) => {
                              return (
                                <span style={{ display: "flex" }}>
                                  <button
                                    onClick={() => {
                                      setTokenToReceive(ele[1]);
                                    }}
                                  >
                                    {ele[1].symbol}
                                  </button>
                                </span>
                              );
                            })}
                            <button
                              onClick={() => {
                                setTokenToReceive(
                                  nativeTokenList[chainSelectedTokenToSell],
                                );
                              }}
                            >
                              {nativeTokenList[chainSelectedTokenToSell].symbol}
                            </button>
                          </div>
                        </Collapse>
                      </div>
                    </DDMenu>
                    <span className="infotxt" style={{ display: "flex" }}>
                      [Current market value $
                      {currencyPrices && tokenToReceive !== null
                        ? currencyPrices[tokenToReceive.symbol]
                        : 0}
                      ]
                    </span>
                    {/* <DDMenuSmall onClick={() => onToggle02(8)}>
                                                {tokenToBuy !== null ? tokenToBuy.symbol : "Select DEX..."}
                                                <div className="DDContainer">
                                                    <Collapse className={"collapse " + (dropDown === 8 ? "active" : "")}
                                                        isOpen={dropDown === 8}
                                                        onChange={({ state }) => { }}
                                                        onInit={({ state }) => { }}>
                                                        <div className="DDSubContainer">
                                                            
                                                            {
                                                                !tokenListLoading ?
                                                                    Object.entries(dexList).map((ele) => {
                                                                        return <button onClick={() => { setTokenToBuy(ele[1]); } }>{ele[1].ammName}</button>
                                                                    })
                                                                    : <ReactLoaderSpinner type="Bars" color="#fff" height={20} width={40} /> 
                                                            }
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            </DDMenuSmall> */}
                  </FormSbxContent>
                </FormMBX01>
                {/* <FormMBX01>
                                    <FormSbxLabel>Amount of tokens</FormSbxLabel>
                                    <FormSbxContent >
                                        <DDMenu className="formBox">
                                            <input type="text" name="budgetAvailable" onChange={(e)=>handleInputChange(e,"budgetAvailable")} value={budgetAvailable} onKeyDown={(e)=>floatOnly(e)} autoComplete="off" />
                                        </DDMenu>
                                            <span className="infotxt">[Current market value  $1,000 / 1:10,000]</span>
                                        
                                    </FormSbxContent>
                                </FormMBX01> */}

                <FormMBX01>
                  <FormSbxLabel>
                    Asking amount{" "}
                    <span
                      data-html="true"
                      data-tip="Choose the DEX to track the average market value on"
                      className="helpIco"
                      currentitem="false"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </FormSbxLabel>
                  <FormSbxContent>
                    <DDMenu className="formBox">
                      <input
                        type="text"
                        name="askingAmount"
                        onChange={(e) => handleInputChange(e, "askingAmount")}
                        value={askingAmount}
                        onKeyDown={(e) => floatOnly(e)}
                        autoComplete="off"
                      />
                    </DDMenu>
                    {/* <span className="infotxt">[Current market value ${(askingAmountUSD).toFixed(6)}]</span> */}
                    {tokenToReceive !== null && tokenToSell !== null ? (
                      <span className="infotxt">
                        [Ask Price: 1{" "}
                        {tokenToSell.symbol +
                          " <> " +
                          askingAmountNative +
                          " " +
                          tokenToReceive.symbol +
                          " | " +
                          askingAmountUSD.toFixed(4)}
                        ]
                      </span>
                    ) : null}
                  </FormSbxContent>
                </FormMBX01>

                {/* <TableMain className='height1'>
                                    <h3>Similar OTC orders that match the listing request</h3>
                                    <table className='border-cell' width="100%" border="0" cellSpacing="0" cellPadding="20" >
                                        <thead>
                                            <tr>
                                                <th align="left" valign="middle" scope="col">Token Pairs</th>
                                                <th align="left" valign="middle" scope="col">Vesting Due Date</th>
                                                <th align="left" valign="middle" scope="col">Supply Available</th>
                                                <th align="left" valign="middle" scope="col">Market Price </th>
                                                <th align="left" valign="middle" scope="col">Asking Amount <span data-html="true" data-tip="test" className="helpIco" currentitem="false"><i className="fas fa-question-circle"></i></span></th>
                                                <th align="left" valign="middle" scope="col">Competitive Value <span data-html="true" data-tip="test" className="helpIco" currentitem="false"><i className="fas fa-question-circle"></i></span></th>
                                                <th align="center" valign="middle" scope="col">OTC Price</th>
                                                <th align="center" valign="center" scope="col">Buy Opportunity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='color-green'>SMART/BNB</td>
                                                <td>Apr 2, 2022</td>
                                                <td>291</td>
                                                <td data-tip="0.2000923818283628361 BNB">0.2000 BNB <Block className='color-dull'>[$0.01986]</Block></td>
                                                <td data-tip="0.2000923818283628361 BNB">0.1991 BNB <Block className='color-dull'>[$0.02385]</Block></td>
                                                <td className='color-red'>-$1 (-0.94%)</td>
                                                <td data-tip="0.2000923818283628361 BNB">0.1991 BNB <Block className='color-dull'>[$0.02385]</Block></td>
                                                <td><a className='btn01'>Buy</a></td>
                                            </tr>
                                            <tr>
                                                <td className='color-green'>SMART/BNB</td>
                                                <td>Apr 2, 2022</td>
                                                <td>291</td>
                                                <td data-tip="0.2000923818283628361 BNB">0.2000 BNB <Block className='color-dull'>[$0.01986]</Block></td>
                                                <td data-tip="0.2000923818283628361 BNB">0.1991 BNB <Block className='color-dull'>[$0.02385]</Block></td>
                                                <td className='color-green'>-$1 (-0.94%)</td>
                                                <td data-tip="0.2000923818283628361 BNB">0.1991 BNB <Block className='color-dull'>[$0.02385]</Block></td>
                                                <td><a className='btn01'>Buy</a></td>
                                            </tr>
                                            <tr>
                                                <td className='color-green'>SMART/BNB</td>
                                                <td>Apr 2, 2022</td>
                                                <td>291</td>
                                                <td data-tip="0.2000923818283628361 BNB">0.2000 BNB <Block className='color-dull'>[$0.01986]</Block></td>
                                                <td data-tip="0.2000923818283628361 BNB">0.1991 BNB <Block className='color-dull'>[$0.02385]</Block></td>
                                                <td className='color-red'>-$1 (-0.94%)</td>
                                                <td data-tip="0.2000923818283628361 BNB">0.1991 BNB <Block className='color-dull'>[$0.02385]</Block></td>
                                                <td><a className='btn01'>Buy</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </TableMain> */}
                <FormBTNBX>
                  {tokenToSell !== null &&
                  Web3.utils.toHex(tokenToSell.chainId) !==
                    globalContext.chainId ? (
                    <button
                      className="PlaOrBTN01"
                      onClick={() => {
                        changeNetwork(Web3.utils.toHex(tokenToSell.chainId));
                      }}
                    >
                      CHANGE NETWORK
                    </button>
                  ) : askLoading ? (
                    <button className="PlaOrBTN01 redBtn">
                      <ReactLoaderSpinner
                        type="ThreeDots"
                        color="#fff"
                        height={15}
                        width={80}
                      />
                    </button>
                  ) : (
                    <button
                      className="PlaOrBTN01 redBtn"
                      onClick={() => handleAsk()}
                    >
                      LIST YOUR SELL OFFER
                    </button>
                  )}

                  <span>Listed OTC request is 10,000 SMART : 1 ETH</span>
                </FormBTNBX>
              </TabContainer>
            </TabContainer>,
          )}
        </>
      </BuyRPopupContainer>

      <ReactTooltip effect="solid" className="myTip" />
    </Modal>
  );
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const BuyRPopupContainer = styled(FlexDiv)`
  max-width: 855px;
  margin: 0 auto;
  ${Media.md2} {
    width: -webkit-fill-available;
    max-width: 100%;
    margin: 0px 15px;
  }
`;

const BrPtitle01 = styled(FlexDiv)`
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 60px;
`;

const FormMBX01 = styled(FlexDiv)`
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 39px;
  &.less-space {
    margin-bottom: 20px;
  }
`;
const FormSbxLabel = styled(FlexDiv)`
  width: 350px;
  justify-content: flex-start;
  align-self: flex-start;
  letter-spacing: -0.5px;
  font-size: 18px;
  font-weight: 300;
  margin: 22px 0 0 0;
  color: #fff;
  ${Media.sm} {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const FormSbxContent = styled(FlexDiv)`
  width: calc(100% - 367px);
  justify-content: flex-start;
  position: relative;
  margin-left: auto;
  ${Media.sm} {
    width: 100%;
  }
  .infotxt {
    position: absolute;
    font-size: 12px;
    color: #9a9a9a;
    right: 0;
    bottom: -22px;
  }

  .md-radio label {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
  }
  .md-radio label:after {
    background-color: #00f02b;
  }

  .redCol {
    .md-radio label:after {
      background-color: #8146e4;
    }
  }

  .md-radio label:before,
  .md-radio input[type="radio"]:checked + label:before {
    border: 2px solid #5a5e67;
    left: -2px;
    top: -2px;
    width: 24px;
    height: 24px;
  }

  .disable {
    position: relative;
    :hover {
      :after {
        content: "Coming Soon";
        background-color: rgba(0, 0, 0, 0.8);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .InputInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0 0;
    .infotxt {
      position: static;
    }
  }
  .flexBox {
    display: flex;
    width: 100%;
    margin: 25px 0 0 0;
  }
  .SlideValue {
    width: 140px;
    height: 67px;
    border: 2px solid #5a5e67;
    border-radius: 10px;
    background: none;
    margin-left: 25px;
    padding: 0 15px;
    color: #ffffff;
  }
`;

const DDMenu = styled(FlexDiv)`
  justify-content: flex-start;
  position: relative;
  height: 66px;
  border-radius: 10px;
  border: 2px solid #5a5e67;
  font-size: 16px;
  font-weight: 400;
  padding: 0 15px;
  i {
    max-width: 80px;
    margin-right: 10px;
  }
  :after {
    content: "";
    border-width: 7px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    right: 16px;
    top: 25px;
  }

  &.formBox {
    input {
      width: 100%;
      height: 64px;
      padding: 6px 0;
      background-color: transparent;
      border: 0px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
    :after {
      display: none;
    }

    &.green {
      input {
        color: #00f02b;
        font-size: 18px;
      }
    }
    &.red {
      input {
        color: #8146e4;
        font-size: 18px;
      }
    }
  }
  &.noBorder {
    :after {
      display: none;
    }
    border: 0px;
  }
  &.ApBX01 {
    position: relative;

    .plsBtn {
      position: absolute;
      left: 4px;
      top: 5px;
      width: 50px;
      height: 50px;
      color: #fff;
      background-color: #5a5e67;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px 0 0 10px;
      :hover {
        background-color: #00f02b;
      }
      i {
        margin: 0;
      }
    }
    .minBtn {
      position: absolute;
      left: 54px;
      top: 5px;
      width: 50px;
      height: 50px;
      color: #fff;
      background-color: #5a5e67;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 10px 10px 0;
      :hover {
        background-color: #8146e4;
      }
      i {
        margin: 0;
      }
    }

    input {
      padding-left: 120px;
      font-weight: 400;
    }
    span {
      background-color: #5a5e67;
      color: #fff;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      min-width: 60px;
      border-radius: 0 6px 6px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
  }

  .DDContainer {
    width: 100%;
    z-index: 10;
    position: absolute;
    left: 0;
    top: calc(100% + 6px);
  }
  .DDSubContainer {
    width: 100%;
    color: white;
    border-radius: 10px;
    background-color: rgba(41, 42, 48, 0.95);
    padding: 10px;
    margin: 0 0 8px 0;
    button {
      width: 100%;
      color: #fff;
      border-radius: 8px;
      text-align: left;
      display: block;
      padding: 10px 14px;
      border: none;
      outline: none;
      :hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
`;

const DDMenuSmall = styled(FlexDiv)`
  justify-content: flex-start;
  position: relative;
  height: 16px;
  border-radius: 10px;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  padding: 0 15px;
  margin: 0 0 -13px 0;
  width: 160px;
  i {
    max-width: 80px;
    margin-right: 10px;
  }
  :after {
    content: "";
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    right: 16px;
    top: 5px;
  }

  &.formBox {
    input {
      width: 100%;
      height: 64px;
      padding: 6px 0;
      background-color: transparent;
      border: 0px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
    :after {
      display: none;
    }

    &.green {
      input {
        color: #00f02b;
        font-size: 18px;
      }
    }
    &.red {
      input {
        color: #8146e4;
        font-size: 18px;
      }
    }
  }
  &.noBorder {
    :after {
      display: none;
    }
    border: 0px;
  }
  &.ApBX01 {
    position: relative;

    .plsBtn {
      position: absolute;
      left: 4px;
      top: 5px;
      width: 50px;
      height: 50px;
      color: #fff;
      background-color: #5a5e67;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px 0 0 10px;
      :hover {
        background-color: #00f02b;
      }
      i {
        margin: 0;
      }
    }
    .minBtn {
      position: absolute;
      left: 54px;
      top: 5px;
      width: 50px;
      height: 50px;
      color: #fff;
      background-color: #5a5e67;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 10px 10px 0;
      :hover {
        background-color: #8146e4;
      }
      i {
        margin: 0;
      }
    }

    input {
      padding-left: 120px;
      font-weight: 400;
    }
    span {
      background-color: #5a5e67;
      color: #fff;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      min-width: 60px;
      border-radius: 0 6px 6px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
  }

  .DDContainer {
    width: 100%;
    z-index: 10;
    position: absolute;
    left: 0;
    top: calc(100% + 6px);
  }
  .DDSubContainer {
    width: 100%;
    color: white;
    border-radius: 10px;
    background-color: rgba(41, 42, 48, 0.95);
    padding: 10px;
    margin: 0 0 8px 0;
    button {
      width: 100%;
      color: #fff;
      border-radius: 8px;
      text-align: left;
      display: block;
      padding: 10px 14px;
      border: none;
      outline: none;
      :hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
`;

const FormBTNBX = styled(FlexDiv)`
  margin-top: 40px;
  margin-bottom: 40px;
  button {
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    height: 70px;
    background: none;
    color: #000;
    border: 2px solid #00f02b;
    border-radius: 10px;
    box-shadow: 0 0 10px #00f02b;
    color: #fff;
    :hover {
      background-color: #00f02b;
      color: #fff;
    }
    ${Media.sm} {
      font-size: 18px;
    }
    &.redBtn {
      box-shadow: 0 0 10px #8146e4;
      border: 2px solid #8146e4;
      border-radius: 10px;
      &:hover {
        background-color: #8146e4;
        color: #fff;
      }
    }
  }
  span {
    font-size: 12px;
    color: #9a9a9a;
    margin: 15px 0 0;
  }

  button.red {
    background-color: #8146e4;
    color: #fff;
    :hover {
      background-color: #ab1012;
      color: #fff;
    }
  }
`;
const Spacer = styled(FlexDiv)`
  height: 1px;
  margin: 20px 0 40px 0;
  background-color: #1e2127;
  position: relative;
  .exchangLink {
    display: block;
    background-color: #0a0c0e;
    width: 62px;
    height: 46px;
    background-image: url(${ExchnICO});
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    right: 230px;
    top: -23px;
    &:hover {
      filter: grayscale(1);
    }
    &.red {
      background-image: url(${ExchnICO02});
    }
    ${Media.sm} {
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
`;

const PopTitle01 = styled(FlexDiv)`
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 60px;
`;

const TabLinkbx = styled(FlexDiv)`
  margin: 10px 0 30px 0;

  button {
    font-size: 21px;
    font-weight: 700;
    color: #fff;
    padding: 12px 8px;
    line-height: 56px;
    border-bottom: 3px solid #393d46;
    width: 50%;
    &:hover,
    &.active {
      color: #00f02b;
      border-bottom: 3px solid #00f02b;
    }
    &.active:nth-last-child(01) {
      color: #8146e4;
      border-bottom: 3px solid #8146e4;
    }
    ${Media.sm} {
      font-size: 18px;
      line-height: normal;
    }
    ${Media.xs} {
      font-size: 14px;
    }
  }
`;

const TabNav = styled.div`
  padding-top: 50px;
  flex-direction: row;
  display: flex;
  width: 100%;
  .tab-Link {
    font-size: 21px;
    font-weight: 700;
    color: #fff;
    flex-grow: 1;
    text-align: center;
    border-bottom: 3px solid #373b44;
    padding-bottom: 30px;
    position: relative;
    &.active {
      color: #00f02b;
      border-bottom-color: #00f02b;
    }
    &.tab02 {
      &.active {
        color: #8146e4;
        border-bottom-color: #8146e4;
      }
    }
    i {
      position: absolute;
      top: -3px;
      margin-left: 3px;
      i {
        font-size: 14px;
        color: #000;
        width: 23px;
        height: 23px;
        background-color: #00f02b;
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
    span {
      position: relative;
    }
    ${Media.md} {
      font-size: 18px;
    }
    ${Media.sm} {
      font-size: 14px;
      padding: 15px 0px;
    }
  }
  ${Media.sm} {
    align-items: stretch;
  }

  .hoverClass:hover span {
    display: none;
  }
  .hoverClass:hover:before {
    content: "Coming Soon";
  }
`;

const TabContainer = styled.div`
  padding: 50px 0 20px;
  width: 100%;
  .Note {
    text-align: center;
    width: 100%;
    font-size: 18px;
    color: #9a9a9a;
    margin-top: 56px;
  }
`;
const DragMBX = styled(FlexDiv)`
  position: relative;
  margin: -9px 0 0 0;

  .dragorInput {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 0px;
  }

  .dragorInput .input-range__slider {
    -webkit-appearance: none;
    appearance: none;
    background: #00f02b;
    cursor: pointer;
    display: block;
    margin-left: -0.9rem;
    margin-top: -1.4rem;
    color: #fff !important;
    font-weight: 700;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    width: 29px;
    height: 29px;
    font-weight: 400;
    border: 0px;
    border-radius: 50%;
    // transform: translateY(-22px);
  }

  .dragorInput .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .dragorInput .input-range__slider-container {
    transition: left 0.3s ease-out;
    z-index: 100;
  }
  .dragorInput .input-range__label {
    color: #8e9195;
    font-size: 12px;
    transform: translateZ(0);
    white-space: nowrap;
  }
  .dragorInput .input-range__label--min,
  .input-range__label--max {
    bottom: auto;
    position: absolute;
  }
  .dragorInput .input-range__label--min {
    left: 0px;
    top: -25px;
    z-index: 2;
  }
  .dragorInput .input-range__label--max {
    right: 0px;
    top: -25px;
  }
  .dragorInput .input-range__label--value {
    position: absolute;
    top: 15px;
    z-index: 2;
    color: #fff;
    left: 0px;
    pointer-events: none;
    transform: translateX(-50%);
  }
  .dragorInput .input-range__label-container {
    left: 0%;
    position: relative;
    color: #8e9195 !important;
    font: 700 12px/16px "IBM Plex Mono", arial;
    pointer-events: none;
  }
  .dragorInput .input-range__label--max .input-range__label-container {
    left: 0%;
  }
  .dragorInput .input-range__track {
    background: #393e48;
    cursor: pointer;
    display: block;
    height: 15px;
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }
  .dragorInput .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }
  .dragorInput .input-range__track--active {
    background: #7f838d;
  }
  .dragorInput .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }
  .dragorInput .input-range__label--value .input-range__label-container {
    color: #fff !important;
    font-weight: 700;
    font-size: 12px;
    position: relative;
  }

  .input-range__track {
    border-radius: 40px;
  }
  .dragorInput .input-range__label--value {
    &:after {
      content: "";
      border: none;
      width: fit-content;
      left: -12px;
      right: 0;
      bottom: -34px;
      color: #fff;
      font-size: 12px;
      font-family: "IBM Plex Mono", arial;
      text-align: center;
      font-weight: 400;
      position: absolute;
    }
  }
`;
const SwapLine = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  margin: 0 0 20px;
  span {
    width: calc(100% - 350px);
    text-align: center;
    margin-left: auto;
  }
  .swap-icon {
    transform: rotate(180deg);
    display: inline-block;
    cursor: pointer;
    img {
      height: 38px;
    }
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 600px;
      left: calc(100% + 15px);
      height: 1px;
      background: #1d2025;
    }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      width: 600px;
      right: calc(100% + 15px);
      height: 1px;
      background: #1d2025;
    }
  }
`;

const TableMain = styled.div`
  position: relative; /* overflow: auto; */
  max-width: 100%;
  max-height: 500px;
  border-top: 1px solid #1d2025;
  padding-top: 37px;
  margin: 60px 0 0 0;
  h3 {
    font-size: 18px;
    color: #00f02b;
    font-weight: 800;
    margin: 0 0 37px 0;
    text-align: center;
  }
  &::-webkit-scrollbar {
    width: 10px;
    background: #545861;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0a0c0e;
    /* outline: 3px solid #545861; */
    box-shadow: inset 0 0 0 2px #545861;
  }
  &.height1 {
    max-height: 427px;
  }
  &.height2 {
    max-height: 281px;
  }
  &.height3 {
    max-height: 750px;
  }
  table {
    width: 100%;
    max-width: 100%;
    border: 1px solid #545861;
    border-collapse: separate;
    font-size: 12px;
    thead {
      position: sticky;
      top: 0;
      th {
        position: sticky;
        padding: 10px 11px;
        top: 0;
        left: 0;
        border-top: 1px solid #545861;
        border-bottom: 1px solid #545861;
        border-right: 1px solid #545861;
        &:last-child {
          border-right: 0;
        }
        .helpIco {
          top: 0;
          right: 0;
        }
      }
    }
    td {
      border-top: 1px solid #545861;
    }
    &.border-cell {
      td {
        border-bottom: 0 solid #545861;
        border-right: 1px solid #545861;
        &:last-child {
          border-right: 0;
        }
      }
    }
  }
  table tr td {
    padding: 9px 11px;
    color: #fff;
    &.bor01 {
      border-right: 5px solid #545861;
    }
    &.bor02 {
      color: #4b4bff;
    }
    ${Media.md} {
      padding: 10px 10px;
    }
    .fa-circle {
      font-size: 8px;
      line-height: 16px;
      vertical-align: top;
    }
  }

  table tr:hover td {
    background-color: #010202;
  }
  table tr th {
    color: #9a9a9a;
    position: relative;
    height: 60px;
    a {
      color: #9a9a9a;
      font-size: 18px;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-last-child(01) {
    }
    &:nth-child(01) {
    }
    &.bor01 {
      border-right: 5px solid #545861;
    }
  }
  .color-dull {
    color: #9a9a9a;
  }
  .color-green {
    color: #00f02b;
  }
  .color-red {
    color: #8146e4;
  }
  .color-yellow {
    color: #e4a029;
  }
  .color-blue {
    color: #3131ff;
  }
  .btn01 {
    width: 85px;
    font-weight: 700;
    height: 40px;
    border-radius: 5px;
    color: #00f02b;
    display: inline-block;
    text-align: center;
    line-height: 36px;
    border: 2px solid transparent;
    cursor: pointer;
    &:hover {
      border: 2px solid #00f02b;
      box-shadow: 0 0 10px #00f02b;
    }
  }
  .btn02 {
    width: 85px;
    font-weight: 700;
    height: 40px;
    border-radius: 5px;
    color: #8146e4;
    display: inline-block;
    text-align: center;
    line-height: 36px;
    border: 2px solid transparent;
    cursor: pointer;
    &:hover {
      border: 2px solid #8146e4;
      box-shadow: 0 0 10px #8146e4;
    }
  }
`;
const Block = styled.div`
  display: block;
`;
const Spinner = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  left: 8px;
  button {
    width: 50px;
    height: 50px;
    background: #5a5e67;
    font-size: 18px;
    color: #ffffff;
    z-index: 10;
    &.plus {
      &:hover {
        background: #00f02b;
      }
    }
    &.minus {
      &:hover {
        background: #8146e4;
      }
    }
  }
`;

const DropMain = styled.div`
  position: relative;
  border-radius: 10px;
  border: 2px solid #5a5e67;
  display: flex;
  width: 100%;
  ${DDMenu} {
    border: 0px;
    &.xs {
      border-left: 2px solid #5a5e67;
      border-radius: 0px;
      width: 108px;
      justify-content: center;
      padding: 0 30px 0 0;
    }
  }
  .infotxt.dropRed {
    ${DDMenuSmall} {
      color: #8146e4;
    }
  }
`;

export default BuyReqPopup;
