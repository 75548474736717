import React, { Component } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

class PriceSupplyLineChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
        options: {
            responsive: true,
            plugins: {
              legend: {
                display: false
              },
              title: {
                display: false,
                // text: "Chart.js Bar Chart",
              },
            },
            scales: {
                y: {
                    ticks: {
                        stepSize: 350000
                    },
                },
            }
        },
        data: {
            labels: [],
            datasets: [{
                label: "Supply",
                data: [],
                backgroundColor: "#19cb58",
            },{
                label: "Supply",
                data: [],
                backgroundColor: "#D22B2B",
            }],
        }
    };
  }

  componentWillReceiveProps(newProps){
    
    if(newProps.priceData !== this.state.seriesOwn){
        console.log(newProps)
        this.setState({
            seriesOwn: newProps.priceData
        })
        this.setState({
            data: {
                labels: newProps.priceData.sort().map(ele=>{return Number(ele).toFixed(4)}),
                datasets: [{
                    label: "Supply",
                    data: newProps.supply,
                    borderColor: '#19cb58',
                    backgroundColor: "#19cb58",
                    borderWidth: 2,
                    stepped: true,
                },{
                    label: "Supply",
                    data: newProps.marketPrices,
                    borderColor: '#D22B2B',
                    backgroundColor: "#D22B2B",
                    stepped: true,
                    borderWidth: 2,
                }],
            },
        })
    }
  }

  render() {
    return (
        <Line options={this.state.options} data={this.state.data} height={"100px"}/>
    );
  }
}

export default PriceSupplyLineChart;
