import React, { Component } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class AskBidBarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
        options: {
            responsive: true,
            plugins: {
              legend: {
                display: false
              },
              title: {
                display: false,
                // text: "Chart.js Bar Chart",
              },
            },
            scales: {
                y: {
                    ticks: {
                        stepSize: 350000
                    },
                },
                
            }
        },
        data: {
            labels: [],
            datasets: [{
                label: "Supply",
                data: [],
                backgroundColor: "#D22B2B",
            }],
        }
    };
  }

  componentWillReceiveProps(newProps){
    
    if(newProps.priceData !== this.state.seriesOwn){
        // console.log(newProps)
        this.setState({
            seriesOwn: newProps.priceData
        })
        this.setState({
            data: {
                labels: newProps.priceData.sort().map(ele=>{return Number(ele).toFixed(2)}),
                datasets: [{
                    label: "Supply",
                    data: newProps.supply,
                    backgroundColor: "#D22B2B",
                    barThickness: 25,
                    maxBarThickness: 25,
                }],
            },
        })
    }
  }

  render() {
    return (
      <Bar options={this.state.options} data={this.state.data} height={"100px"}/>
    );
  }
}

export default AskBidBarChart;
