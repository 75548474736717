import React, { Component } from 'react';
import styled from 'styled-components';
import Gs from '../Theme/globalStyles';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import LeftMBX from '../Component/leftBar'
import GetwayStatus from '../Component/popup/gatewayStatus'
import Collapse from '@kunukn/react-collapse';
import Media from '../Theme/media-breackpoint';
import { Scrollbars } from 'react-custom-scrollbars';

class Gateway extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 1,
            dropDown: false,
            popup01: false
        }
    }

    render() {

        return (
            <>
                <MainContainer>
                    <LeftMBX />
                    <RightContainer>
                        <DSTitle01 className="wow fadeInUp" data-wow-delay="0.1s">
                            Buy Tokens From The Gateway
                            <div className="linkBX01"> <a href="javascript:void(0);">How it works?</a> <span>|</span> <a href="javascript:void(0);" onClick={() => { this.setState({ popup01: true }) }}>Buy tokens from the Gateway</a></div>
                        </DSTitle01>
                        <PlacOrdMBX className="wow fadeInRight" data-wow-delay="0.1s">
                            <Scrollbars className="custom-scroll"
                                    autoHeight autoHide autoHideTimeout={1000} autoHideDuration={200}
                                    autoHeightMin={400}
                                    autoHeightMax={500}
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                                    renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                                    renderView={props => <div {...props} className="view"/>}>
                                <OpfyTableBX>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="20">
                                        <tbody>
                                            <tr>
                                                <th align="left" valign="middle" scope="col">Token Symbol </th>
                                                <th align="left" valign="middle" scope="col">Pending Amount</th>
                                                <th align="left" valign="middle" scope="col">Face Value</th>
                                                <th align="left" valign="middle" scope="col">Incentive Fee <span data-html='true' data-tip='Not all projects utilize the PDO to help with liquidity. If your token does not have an active PDO on PDO.Finance please reach out to the token admin and have them launch a free PDO' className="helpIco"><i className="fas fa-question-circle"></i></span></th>
                                                <th align="left" valign="middle" scope="col">Accepting Token</th>
                                                <th align="center" valign="middle" scope="col">BUY</th>
                                            </tr>
                                            <tr align="left" valign="middle">
                                                <td>JNTR</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>BNB</td>
                                                <td align="center" valign="middle"><button className='btn-primary'>Buy</button></td>
                                            </tr>
                                            <tr align="left" valign="middle">
                                                <td>SMART</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>BNB</td>
                                                <td align="center" valign="middle"><button className='btn-primary'>Buy</button></td>
                                            </tr>
                                            <tr align="left" valign="middle">
                                                <td>PDO</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>BNB</td>
                                                <td align="center" valign="middle"><button className='btn-primary'>Buy</button></td>
                                            </tr>
                                            <tr align="left" valign="middle">
                                                <td>DEGEN</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>BNB</td>
                                                <td align="center" valign="middle"><button className='btn-primary'>Buy</button></td>
                                            </tr>
                                            <tr align="left" valign="middle">
                                                <td>FREEZ</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>BNB</td>
                                                <td align="center" valign="middle"><button className='btn-primary'>Buy</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Tfooter>
                                        <Tnav>Page 
                                            <SelectCustom>
                                                <select>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                </select>
                                            </SelectCustom>
                                            result 1-10 of 300</Tnav>
                                    </Tfooter>
                                </OpfyTableBX>
                            </Scrollbars>


                            <DSTitle02 className="wow fadeInUp v1" data-wow-delay="0.1s">Your pending buy orders</DSTitle02>
                            <Scrollbars className="custom-scroll"
                                    autoHeight autoHide autoHideTimeout={1000} autoHideDuration={200}
                                    autoHeightMin={229}
                                    autoHeightMax={229}
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                                    renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                                    renderView={props => <div {...props} className="view"/>}>
                                <OpfyTableBX>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="20">
                                        <tbody>
                                            <tr>
                                                <th align="left" valign="middle" scope="col">Token Symbol</th>
                                                <th align="left" valign="middle" scope="col">Liquidity Channel</th>
                                                <th align="left" valign="middle" scope="col">Pending Amount</th>
                                                <th align="left" valign="middle" scope="col">Face Value</th>
                                                <th align="left" valign="middle" scope="col">Incentive Fee</th>
                                                <th align="left" valign="middle" scope="col">Proof</th>
                                                <th align="center" valign="middle" scope="col">Claim Fund</th>
                                            </tr>
                                            <tr align="left" valign="middle">
                                                <td>SMART</td>
                                                <td>PDO</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0%</td>
                                                <td className="color02">0xe39...075D</td>
                                                <td align="center" valign="middle"><button className='deleteBTN'><i className="far fa-times-circle"></i></button></td>
                                            </tr>
                                            <tr align="left" valign="middle">
                                                <td>PDO</td>
                                                <td>PDO</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0%</td>
                                                <td className="color02">0xe39...075D</td>
                                                <td align="center" valign="middle"><button className='deleteBTN'><i className="far fa-times-circle"></i></button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </OpfyTableBX>
                            </Scrollbars>
                        </PlacOrdMBX>
                    </RightContainer>
                </MainContainer>

                <GetwayStatus isOpen={this.state.popup01} dismiss={() => { this.setState({ popup01: false }) }} />
                <ReactTooltip effect="solid" className="myTip" />
            </>
        );
    }
    onToggle = index =>
        this.setState(state => ({ index: state.index === index ? null : index }));
    onToggle02 = dropDown =>
        this.setState(state => ({ dropDown: state.dropDown === dropDown ? null : dropDown }));
}



// Common Style Div 


const FlexDiv = styled.div`
display: flex; align-items: center; justify-content:center; flex-wrap:wrap; width:100%;
`;

const MainContainer = styled(FlexDiv)`
 width: 100%; align-items:stretch; justify-content:flex-start; 
`
const RightContainer = styled(FlexDiv)`
  width:calc(100% - 82px); align-items: flex-start; justify-content:flex-start; padding:18px 56px; align-content:baseline;
  ${Media.md} {
    padding:25px 20px;
  }
  ${Media.sm} {
    width:100%;
  }
`;

const DDMenu = styled(FlexDiv)`
    justify-content:flex-start; position:relative; height:56px; border-radius:10px;  border:2px solid #5a5e67;  
    font-size: 16px; font-weight:400; padding:0 15px;
    i{max-width:80px; margin-right:10px;}
    :after{ content: ""; border-width:7px; border-style:solid; border-color:#fff transparent transparent transparent; position:absolute;   right: 16px;  top: 25px; } 
    &.formBox{
        input{ width:100%; height:52px; padding:6px 0; background-color:transparent; border:0px; color:#fff; font-size: 16px; font-weight:400;}
        :after{ display:none;}
    }
    &.noBorder{
        :after{ display:none;}
        border:0px;
    } 
    .DDContainer{ width:100%; z-index:10;   position:absolute; left:0; top:calc(100% + 6px);}
    .DDSubContainer{ width:100%;  color:white; border-radius:10px; background-color:rgba(41,42,48,0.95); padding:10px; 
        margin:0 0 8px 0; 
        button{ width:100%; color:#fff; border-radius:8px; text-align:left;display:block; padding:10px 14px; border:none; outline:none; 
            :hover{  background-color:rgba(255,255,255,0.05); }
        } 
    } 
`;

const DSTabLinkBX = styled(FlexDiv)`
    justify-content:flex-start; margin-bottom:50px;
    button{ font-size:16px; font-weight:700; color:#fff; margin-right:35px; padding: 0;
        :hover, &.active{ color:#00f02b;}
    }
`;

const DSTitle01 = styled(FlexDiv)`
    justify-content:space-between; font:700 36px/40px 'Kanit', arial; color:#00f02b; 
    .DSrPart{ display: flex; align-items:center; justify-content:center; font-size:16px; color:#fff; font-weight:700; }
    ${Media.xs} {
        font:700 30px/34px 'Kanit', arial; display:block;
    }
    .linkBX01{ display: flex; align-items:center; justify-content:center; font-size:16px; color:#5a5e67; font-weight:300; text-transform:initial;
        a{ color:#00f02b; margin:0 8px; font-family:'IBM Plex Mono', Arial,sans-serif; font-weight:700; 
            :hover{text-decoration:underline;}
            :first-child{
                ${Media.xs} 
                {
                    margin:0px 8px 0px 0px;
                }
            }
        }
        ${Media.xs} {
            justify-content:flex-start; flex-flow: column; align-items: flex-start; margin-top:15px;
            a {margin: 0;}
            span {display: none;}
        }
    }
`;

const DSTitle02 = styled(FlexDiv)`
    justify-content:flex-start; letter-spacing:-0.25px; font:700 21px/40px 'IBM Plex Mono', arial; color:#ffffff; margin: 40px 0px 32px;
    &.v1{
        ${Media.sm} {
            margin:40px 0px 15px;
        }   
    }
`;

const TabNav = styled(FlexDiv)`
padding-top:50px; flex-direction:row; 
    .tab-Link{font-size:24px;font-weight:700;color:#fff;width:33.33%;text-align:center;border-bottom:3px solid #393d46;padding-bottom:30px; position:relative;
        i{ position:absolute; top: -3px;  margin-left: 3px;
            i{font-size:14px; color:#000; width:23px; height:23px; background-color:#00f02b; font-style:normal; display:flex; align-items:center;justify-content:center; border-radius:50%;}
        } 
    }
    .tab-Link.active{color:#00f02b;border-bottom-color:#00f02b;} 
`
const OpfyTableBX = styled(FlexDiv)` 
    margin:0px; font-size:14px; color:#fff; justify-content:flex-start;
    p{ color:#00f02b; font-size:12px; margin-top:15px; text-align:left; width:100%;} 
    .sortLink{ position:absolute; right:12px; color:#9a9a9a; top: 18px; font-size:18px; :hover{color:#fff;}}
    .deleteBTN{ background: transparent; border:none; font-size:24px; color:#53575d; margin:0 auto; :hover{color:#c32b2d;}    }
    .OpfyBTNbar{ display: flex; align-items:center; justify-content:flex-start; width:210px;

        &.v2{width:180px; justify-content: center;}

        .btn01{width:155px; font-weight:700; height:40px; border:2px solid #00f02b; border-radius:5px; color:#00f02b; :hover{ color:#000; background-color:#00f02b;} }
        .btn02{ color:#858686; padding:10px; margin-left:6px; :hover{ color:#c32b2d; }}
    }
    .btn-primary {font-size: 14px; font-weight: 700; color: #00f02b; border-radius: 8px; background-color: inherit; border: 2px solid transparent; box-shadow: 0 0 6px transparent; padding: 8px 10px; display: block; width: 100%; text-transform: uppercase;
    &:hover {border: 2px solid #00f02b; box-shadow: 0 0 6px #00f02b;}}
    table{
        min-width: max-content; width:100%; border: 1px solid #1e2127;
    }
    table tr td{ border:1px solid #1e2127; padding:10px 20px; height: 60px; background: #010202;
        &:nth-last-child(01){ border-right:0px; }
        &:nth-child(01){ border-left:0px; color:#00f02b;  a{color:#4b4bff; :hover{text-decoration:underline}}} 
        &.bor01{border-right:5px solid #1e2127; }
        &.color02{ color:#3131ff;}
    }
    table tr th{ border:2px solid #1e2127; border-top: 1px; color:#9a9a9a; position:relative; width: 14.28%; background: #010202;
        &:nth-last-child(01){ border-right:0px;}
        &:nth-child(01){ border-left:0px;}
        &.bor01{border-right:5px solid #1e2127; }
    } 
    table tr:hover td{background-color: #0b0c0f;}
`;

const PlacOrdMBX = styled(FlexDiv)`
    padding:0px 0px 50px;  color:#fff; border-top:1px solid #1e2127; margin-top:50px;
`;
const Tfooter = styled.div `
    display: flex; align-items: center; justify-content: flex-end; font-size: 12px; color: #aaaaaa; padding: 22px 0 30px;
    width: 100%; 
    p {color: #3b3e4b;}
    a {color: #aaa; margin: 0 10px;}
    a:hover {color: #91dc27;}
`
const SelectCustom = styled.div `
    display: inline-block; position: relative; margin: 0 10px;
    select {background: #010202; min-width: 80px; color: #9a9a9a; font-size: 14px; border: 0; height: 30px;  border-radius: 4px; padding: 0 10px;   appearance: none;}
    &:after {
        content: "\f0d7"; font-family: "Font Awesome 5 Free"; color: #aaa; font-weight: 900; position: absolute; right: 5px; top: 8px;
    }
`
const Tnav = styled.div `

`

export default Gateway;